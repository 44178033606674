<template>
  <div>
    <!-- Header -->
    <div class="mb-3">
      <ReportHeader
        title="Projects Status Statistics"
        group-filter
        period-filter
        category-filter
        team-filter
        @filterChanged="load"
      />
    </div>
    <!-- Loading Indicator-->
    <div
      v-if="loading"
      class="d-flex align-items-center justify-content-center p-5"
    >
      <div class="spinner-border" />
    </div>
    <div
      v-else-if="error"
      class="alert alert-success"
    >
      {{ error }}
    </div>
    <div v-else>
      <!-- Report Grid -->
      <div
        style="display: grid;"
        class="bg-light rounded-lg py-5"
        :style="{ 'grid-template-columns': 'repeat(' + (states.length * 2) + ', 1fr)' }"
      >
        <!-- States Header -->
        <div
          v-for="(card, index) in headers"
          :key="index + 1000"
          class="d-flex justify-content-center align-items-end mx-1"
          :style="{ 'grid-column': card.column + ' / span 2' }"
        >
          <!--State Name -->
          <div
            class="bg-brand text-white text-capitalize text-center text-truncate rounded-lg px-3 py-1"
            :class="card.class"
          >
            {{ card.title }}
          </div>
        </div>
        <!-- Metric Block -->
        <div
          v-for="(metric, index) in metrics"
          :key="index"
          class="border-right border-brand position-relative px-1"
          :class="(metric.firstInRow ? 'border-left ' : '') + (metric.gridRow === 2 ? 'pt-5' : 'pt-1')"
          :style="{ 'grid-row': metric.gridRow, 'grid-column': metric.gridColumn + ' / span ' + metric.gridSize }"
        >
          <!-- Metric Block -->
          <div
            class="d-flex flex-wrap align-items-center px-3 py-2"
            style="height: 100%;"
            :class="(metric.title ? '' : 'justify-content-center')"
            :style="{ 'background-color': metric.bgColor, 'color': metric.color, 'cursor': metric.id ? 'pointer' : 'auto' }"
            @click="editMetric(metric)"
          >
            <!-- Metric Name -->
            <div
              v-if="metric.title"
              class="lead font-weight-bold mr-3"
            >
              {{ metric.title }}
            </div>
            <!-- Metric Value -->
            <div>
              {{ metric.value.avg_duration }} DAYS
              <span v-if="metric.title">(Avg)</span>
            </div>
          </div>
        </div>
      </div>
      <!-- Download Button -->
      <ReportDownloadButtons
        csv-filename="project_duration_report.csv"
        :csv-fields="csvFields"
        :csv-data="csvData"
      />
      <!-- Cards -->
      <div class="d-flex flex-wrap mt-2">
        <!--  Metric Cards -->
        <ProjectsDurationMetricCard
          v-for="(metric, index) in metricCards"
          :key="index + 2000"
          :metric="metric"
          @click="editMetric(metric)"
        />
        <!-- New Metric Button -->
        <ProjectsDurationMetricCard
          :metric="{}"
          blank
          class="noprint"
          @click="addMetric"
        />
      </div>
    </div>
    <!-- Metric Modal -->
    <ProjectsDurationMetricModal
      ref="metricModal"
      :states="states"
      @saved="onMetricSaved"
    />
  </div>
</template>

<script>
import ReportHeader from './ReportHeader'
import reportApi from '@/api/report'
import ProjectsDurationMetricModal from './ProjectsDurationMetricModal'
import ProjectsDurationMetricCard from './ProjectsDurationMetricCard'
import ReportDownloadButtons from './ReportDownloadButtons'

export default {
  components: {
    ProjectsDurationMetricCard,
    ProjectsDurationMetricModal,
    ReportHeader,
    ReportDownloadButtons,
  },
  data () {
    return {
      states: ['New', 'Assigned Team', 'Quoted', 'Open', 'Pending Closure', 'Closed'],
      metrics: [],
      csvFields: ['Project ID', 'State', 'Start Date', 'End Date', 'Next State', 'State Duration (Days)'],
      csvData: [],
      standardMetrics: [
        { title: 'Project Lifecycle', sourceState: 'New', destinationState: 'Closed' },
        { title: 'Quoting Project', sourceState: 'New', destinationState: 'Quoted' },
        { title: 'Approving Quote', sourceState: 'Quoted', destinationState: 'Open' },
        { title: 'Closing Project', sourceState: 'Pending Closure', destinationState: 'Closed' },
      ],
      colors: [
        '#17A2B8',
        '#63B3ED',
        '#B794F4',
        '#4FD1C5',
        '#F56565',
        '#F6AD55',
        '#7F9CF5',
        '#ED64A6',
        '#48BB78',
      ],
      loading: false,
      error: '',
    }
  },
  computed: {
    metricCards () {
      return this.metrics.filter(metric => !!metric.card)
    },
    headers () {
      return this.states.map((state, index) => {
        return { title: state, column: index * 2 + 1 }
      })
    },
  },
  methods: {
    load () {
      const { groupId, teamId, startDate, endDate, serviceGroups, services } = this.$route.query

      this.error = ''
      this.metrics = []

      if (!groupId || !startDate || !endDate) return

      let metrics = this.loadMetrics()
      // create a promise for each metric to retrieve data via api
      const promises = metrics.map(m => {
        return reportApi.getStateMetricReport(groupId, teamId, m.sourceState, m.destinationState, startDate, endDate, serviceGroups, services)
      })
      // append api call for individual state data
      promises.push(reportApi.getStateDurationReport(groupId, teamId, startDate, endDate, serviceGroups, services))

      this.loading = true
      Promise.all(promises).then(response => {
        // process api state data
        const projects = response.pop()
        const stateMetrics = this.getStateMetrics(projects.data)
        this.loadCsv(projects.data)

        // save data for custom/standard metrics
        response.forEach((resp, index) => {
          metrics[index].value = resp.data
        })
        // append standard and custom metrics to the state metrics
        metrics = stateMetrics.concat(metrics)
        this.placeMetricsOnGrid(metrics)
        this.metrics = metrics
        this.loading = false
      }).catch(error => {
        this.error = error.message
        this.loading = false
      })
    },
    editMetric (metric) {
      if (!metric.id) return
      this.$refs.metricModal.show(metric)
    },
    addMetric () {
      this.$refs.metricModal.show({})
    },
    getStateMetrics (projects) {
      if (this.states.length === 0) return []

      const metrics = []
      // Create a metric for each state
      for (let i = 0; i < this.states.length - 1; i++) {
        const metric = {
          sourceState: this.states[i],
          destinationState: this.states[i + 1],
          bgColor: 'var(--gray-300)',
          color: 'var(--gray-600)',
          value: { avg_duration: 0 },
        }
        const durations = projects.filter(p => {
          return p.state_name === metric.sourceState && p.next_state_name === metric.destinationState
        }).map(p => p.state_duration)
        if (durations.length > 0) {
          metric.value.avg_duration = Math.round(durations.reduce((a, b) => a + b) / durations.length)
        }
        metrics.push(metric)
      }
      return metrics
    },
    loadMetrics () {
      // get custom metrics
      const customMetrics = localStorage.projectLifecycleReportCustomMetrics ? JSON.parse(localStorage.projectLifecycleReportCustomMetrics) : []
      // append them to the standard metrics list
      const metrics = this.standardMetrics.concat(customMetrics)
      // assign colours
      return metrics.map((metric, index) => {
        return {
          ...metric,
          color: 'white',
          bgColor: this.colors[index % this.colors.length],
          card: true,
        }
      })
    },
    placeMetricsOnGrid (metrics) {
      let currentRow = 1
      let previousDestIndex = this.states.length

      metrics.forEach(metric => {
        const sourceIndex = this.states.indexOf(metric.sourceState)
        const destIndex = this.states.indexOf(metric.destinationState)
        if (sourceIndex < previousDestIndex) {
          currentRow += 1
          metric.firstInRow = true
        }
        metric.gridRow = currentRow
        metric.gridSize = (destIndex - sourceIndex) * 2
        metric.gridColumn = (sourceIndex + 1) * 2
        previousDestIndex = destIndex
      })
    },
    onMetricSaved () {
      this.load()
    },
    loadCsv (projects) {
      this.csvData = projects.map(p => {
        return [
          p.project_id, p.state_name, p.start_date, p.end_date,
          p.next_state_name, p.state_duration,
        ]
      })
    },
  },
}
</script>
