<template>
  <Modal
    ref="newMetricModal"
    title="Save Metric"
  >
    <BaseForm @submit="saveMetric">
      <!-- Title -->
      <BaseInput
        v-model="metric.title"
        name="title"
        :maxlength="32"
        title="Title"
        trim
        required
      />
      <div class="row">
        <!-- Source State -->
        <BaseSelect
          v-model="metric.sourceState"
          name="sourceState"
          class="col"
          :options="states"
          title="Source State"
          required
        />
        <!-- Destination State -->
        <BaseSelect
          v-model="metric.destinationState"
          name="destinationState"
          class="col"
          :options="states"
          title="Destination State"
          required
        />
      </div>
      <template #buttons>
        <!-- Add Metric -->
        <button
          class="btn btn-primary"
          type="submit"
        >
          Save
        </button>
        <!-- Delete Metric -->
        <button
          v-if="metric.id"
          class="btn btn-light text-danger"
          @click.prevent="deleteMetric"
        >
          Delete
        </button>
      </template>
    </BaseForm>
  </Modal>
</template>

<script>
import Modal from '../../components/Modal'

export default {
  components: {
    Modal,
  },
  props: {
    states: {
      type: Array,
      required: true,
    },
  },
  data () {
    return {
      metric: {},
    }
  },
  methods: {
    deleteMetric () {
      let customMetrics = localStorage.projectLifecycleReportCustomMetrics ? JSON.parse(localStorage.projectLifecycleReportCustomMetrics) : []
      customMetrics = customMetrics.filter(metric => metric.id !== this.metric.id)

      localStorage.projectLifecycleReportCustomMetrics = JSON.stringify(customMetrics)

      this.$refs.newMetricModal.hide()

      this.$emit('saved')
    },
    saveMetric () {
      const { id, sourceState, destinationState, title } = this.metric
      const sourceStateIndex = this.states.indexOf(sourceState)
      const destinationStateIndex = this.states.indexOf(destinationState)
      if (sourceStateIndex >= destinationStateIndex) {
        alert('Source State should be after Destination State')
        return
      }

      const customMetrics = localStorage.projectLifecycleReportCustomMetrics ? JSON.parse(localStorage.projectLifecycleReportCustomMetrics) : []
      const metric = { id, sourceState, destinationState, title }

      if (metric.id) {
        const index = customMetrics.findIndex(m => m.id === metric.id)
        if (index >= 0) {
          this.$set(customMetrics, index, metric)
        } else {
          customMetrics.push(metric)
        }
      } else {
        metric.id = new Date()
        customMetrics.push(metric)
      }

      localStorage.projectLifecycleReportCustomMetrics = JSON.stringify(customMetrics)

      this.$refs.newMetricModal.hide()

      this.$emit('saved')
    },
    show (metric) {
      this.metric = Object.assign({}, metric)
      delete this.metric.sourceStateIndex
      delete this.metric.destinationStateIndex
      this.$refs.newMetricModal.show()
    },
  },
}
</script>
