<template>
  <div class="m-1">
    <!-- Metric Card -->
    <div
      v-if="!blank"
      class="metric-card bg-light rounded-lg px-4 py-3"
    >
      <!-- Header -->
      <div
        class="mb-4"
        :style="{ 'color': metric.bgColor }"
      >
        <!-- Name -->
        <div
          class="metric-title text-truncate lead font-weight-bold"
          @click="$emit('click')"
        >
          {{ metric.title }}
        </div>
        <!-- State Range -->
        <div class="text-capitalize text-truncate">
          {{ metric.sourceState }} <span class="text-lowercase">to</span> {{ metric.destinationState }}
        </div>
      </div>
      <!-- Statistics -->
      <div class="mb-4">
        <!-- Started -->
        <div class="d-flex">
          <div class="flex-grow-1 text-gray-600">
            Started:
          </div>
          <div class="text-gray-600">
            {{ metric.value.new }} Projects
          </div>
        </div>
        <!-- Active -->
        <div class="d-flex">
          <div class="flex-grow-1 text-gray-600">
            Active:
          </div>
          <div class="text-gray-600">
            {{ metric.value.backlog }} Projects
          </div>
        </div>
        <!-- Exited -->
        <div class="d-flex">
          <div class="flex-grow-1 text-gray-600">
            Exited:
          </div>
          <div class="text-gray-600">
            {{ metric.value.closed }} Projects
          </div>
        </div>
        <!-- Average Time -->
        <div class="d-flex">
          <div class="flex-grow-1 text-gray-600">
            Average time to Exited:
          </div>
          <div class="text-gray-600">
            {{ metric.value.avg_duration }} Days
          </div>
        </div>
      </div>
      <!-- Projects-->
      <div>
        <!-- Top 5 projects -->
        <div class="text-gray-600 mb-1">
          Top 5 Longest Exited projects:
        </div>
        <div
          v-for="project in metric.value.top_projects"
          :key="project.project_id"
          class="d-flex mb-1"
        >
          <!-- Project ID -->
          <router-link
            class="flex-grow-1 text-gray-500"
            :to="{ name: 'show_project_info', params: { projectId: project.project_id } }"
          >
            Project {{ project.project_id }}
          </router-link>
          <!-- Project Duration -->
          <div class="text-gray-500">
            {{ project.duration }} Days
          </div>
        </div>
      </div>
    </div>
    <!-- Blank Card -->
    <div
      v-else
      class="metric-card bg-light d-flex align-items-center justify-content-center rounded-lg hoverable-bg-gray-200"
      style="cursor: pointer;"
      @click="$emit('click')"
    >
      <div>
        <!-- Icon -->
        <div class="d-flex justify-content-center mb-2">
          <div class="rounded-circle bg-gray-300 p-2">
            <font-awesome-icon
              class="text-gray-800"
              icon="plus"
              fixed-width
            />
          </div>
        </div>
        <!-- Label -->
        <div class="lead text-gray-600">
          New Metric
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    metric: {
      type: Object,
      required: true,
    },
    blank: {
      type: Boolean,
      default: false,
    },
  },
}
</script>

<style scoped>
    .metric-card {
        max-width: 300px;
        min-width: 300px;
        max-height: 360px;
        min-height: 360px;
    }
    .metric-title:hover {
        text-decoration: underline;
        cursor: pointer;
    }
</style>
