<template>
  <div>
    <!-- Preset Periods -->
    <div
      v-if="presets"
      class="d-flex align-items-center"
    >
      <!-- Period -->
      <div class="font-weight-bold mr-2">
        Period:
      </div>
      <!-- Presets Dropdown -->
      <div
        class="dropdown "
      >
        <div
          class="dropdown-toggle font-weight-bold form-control"
          data-toggle="dropdown"
          style="cursor: pointer;"
        >
          {{ selectedPreset.title }}
        </div>
        <div class="dropdown-menu">
          <div
            v-for="(preset, index) in presets"
            :key="index"
            class="dropdown-item pointer"
            @click="selectedPreset = preset"
          >
            {{ preset.title }}
          </div>
        </div>
      </div>
    </div>
    <!-- Custom Period -->
    <div
      v-else
      class="d-flex align-items-center"
    >
      <!-- Period -->
      <div class="font-weight-bold mr-2">
        Period:
      </div>
      <!-- Start Date -->
      <div style="margin-bottom: -12px; max-width: 120px;">
        <BaseInputDate
          v-model="startDate"
          placeholder="Start Date"
          title=""
          required
          :max="endDate"
          name="startDate"
          align-right
        />
      </div>
      <div class="mx-1">
        -
      </div>
      <!-- End Date -->
      <div style="margin-bottom: -12px; max-width: 120px;">
        <BaseInputDate
          v-model="endDate"
          name="endDate"
          placeholder="End Date"
          required
          :min="startDate"
          title=""
          align-right
        />
      </div>
    </div>
  </div>
</template>

<script>
import moment from 'moment'
import BaseInputDate from '../../../components/forms/BaseInputDate'

export default {
  components: {
    BaseInputDate,
  },
  props: {
    presets: {
      type: Array,
      default: null,
    },
  },
  computed: {
    selectedPreset: {
      get () {
        if (!this.presets) return null
        return this.presets.filter(preset => preset.startDate === this.startDate && preset.endDate === this.endDate)[0]
      },
      set (value) {
        const location = {
          path: this.$route.path,
          query: { ...this.$route.query, startDate: value.startDate, endDate: value.endDate },
        }
        this.$router.replace(location)
      },
    },
    startDate: {
      get () {
        if (!this.$route.query.startDate) return null
        return moment(this.$route.query.startDate).format('YYYY-MM-DD')
      },
      set (value) {
        const location = {
          path: this.$route.path,
          query: { ...this.$route.query, startDate: value },
        }
        this.$router.replace(location)
      },
    },
    endDate: {
      get () {
        if (!this.$route.query.endDate) return null
        return moment(this.$route.query.endDate).format('YYYY-MM-DD')
      },
      set (value) {
        const location = {
          path: this.$route.path,
          query: { ...this.$route.query, endDate: value },
        }
        this.$router.replace(location)
      },
    },
  },
  watch: {
    '$route' () {
      this.setDefaultDates()
    },
  },
  beforeMount () {
    this.setDefaultDates()
  },
  methods: {
    setDefaultDates () {
      if (this.presets && !this.selectedPreset) {
        //  Set default preset period
        this.selectedPreset = this.presets[0]
      } else {
        //  Set default dates for custom period
        if (!this.startDate) {
          this.startDate = moment().startOf('month').format('YYYY-MM-DD')
        }
        if (!this.endDate) {
          this.endDate = moment().format('YYYY-MM-DD')
        }
      }
    },
  },
}
</script>
