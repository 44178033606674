<template>
  <div
    class="d-flex align-items-center"
  >
    <!-- Label -->
    <div class="font-weight-bold mr-2">
      Year:
    </div>
    <!-- Input -->
    <div style="margin-bottom: -12px;">
      <BaseInputNumber
        v-model="year"
        :min="2017"
        :max="9999"
        name="year"
        title=""
        required
      />
    </div>
  </div>
</template>

<script>
import moment from 'moment'

export default {
  computed: {
    year: {
      get () {
        return this.$route.query.year
      },
      set (value) {
        const location = {
          path: this.$route.path,
          query: { ...this.$route.query, year: value },
        }
        this.$router.replace(location)
      },
    },
  },
  watch: {
    '$route' () {
      this.setDefaultYear()
    },
  },
  beforeMount () {
    this.setDefaultYear()
  },
  methods: {
    setDefaultYear () {
      if (!this.year) {
        this.year = moment().format('YYYY')
      }
    },
  },
}
</script>
