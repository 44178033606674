<template>
  <div class="d-flex flex-wrap align-items-center flex-row-reverse">
    <div
      class="ml-4 mt-3"
    >
      <a @click.prevent="download">
        <button class="btn btn-light">
          <font-awesome-icon icon="download" />
          Download CSV
        </button>
      </a>
      <button
        v-if="pdfBtn"
        class="btn btn-light"
        @click="openPrint"
      >
        <font-awesome-icon icon="download" />
        Save as PDF
      </button>
    </div>
  </div>
</template>

<script>
import PapaParse from 'papaparse'
import Download from 'downloadjs'

export default {
  props: {
    csvFilename: {
      type: String,
      required: true,
    },
    csvFields: {
      type: Array,
      default: () => { return [] },
    },
    csvData: {
      type: Array,
      required: true,
    },
    pdfBtn: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    openPrint () {
      window.print()
    },
    download () {
      const csv = PapaParse.unparse({ fields: this.csvFields, data: this.csvData })
      Download(csv, this.csvFilename, 'application/csv')
    },
  },
}
</script>
