<template>
  <div>
    <!-- Loading Indicator -->
    <div v-if="loading">
      <div class="spinner-border spinner-border-sm" />
    </div>
    <!-- Input -->
    <div
      v-else
      class="d-flex align-items-center "
      style="cursor: pointer;"
      @click="startFilter"
    >
      <!-- Label -->
      <div class="font-weight-bold mr-2">
        Categories:
      </div>
      <!-- Current Category -->
      <div
        class="form-control"
        :class="selectedCategoryIds.length > 0 ? 'text-primary' : ''"
      >
        {{ selectedCategoryIds.length === 0 ? 'Any' : (selectedCategoryIds.length + ' Categories') }}&nbsp;&#9207;
      </div>
    </div>
    <!-- Category Selector Modal -->
    <Modal
      ref="modal"
      title="Category Filter"
      scrollable
      cl
    >
      <!-- Done Action -->
      <template #header-action>
        <button
          class="btn btn-lg btn-primary"
          @click.prevent="onDoneFiltering"
        >
          Done
        </button>
      </template>

      <!-- Select Actions -->
      <div class="d-flex flex-row-reverse mb-3">
        <button
          class="btn btn-light mx-1"
          @click="selectAll(true)"
        >
          Select All
        </button>
        <button
          class="btn btn-light mx-1"
          @click="selectAll(false)"
        >
          Clear All
        </button>
      </div>
      <!--  Categories -->
      <div
        v-for="(category, index) in selectableCategories"
        :key="index"
        class="d-flex align-items-center rounded-lg px-3 py-2 mb-1"
        style="cursor: pointer;"
        :class="category.selected ? 'text-info font-weight-bold bg-gray-300' : 'text-gray-600 hoverable'"
        @click="category.selected = !category.selected"
      >
        <div class="flex-grow-1">
          {{ category.name }}
        </div>
        <div v-show="category.selected">
          <font-awesome-icon
            icon="check-square"
            fixed-width
          />
        </div>
      </div>
    </Modal>
  </div>
</template>

<script>
import Modal from '../../../components/Modal'
import groupApi from '@/api/group'

export default {
  components: {
    Modal,
  },
  data () {
    return {
      loading: false,
      services: [],
      serviceGroups: [],
      selectableCategories: [],
    }
  },
  computed: {
    useGroups () {
      return this.serviceGroups.length > 0
    },
    filterParam () {
      return this.useGroups ? 'serviceGroups' : 'services'
    },
    categories () {
      return this.useGroups ? this.serviceGroups : this.services
    },
    selectedCategoryIds: {
      get () {
        const categories = this.$route.query[this.filterParam] || []
        if (Array.isArray(categories)) {
          return categories
        }
        return [categories]
      },
      set (value) {
        const location = {
          path: this.$route.path,
          query: {
            ...this.$route.query,
            serviceGroups: this.useGroups ? value : [],
            services: this.useGroups ? [] : value,
          },
        }
        this.$router.replace(location)
      },
    },
  },
  watch: {
    '$route.query.groupId' () {
      this.load()
    },
  },
  beforeMount () {
    this.load()
  },
  methods: {
    compareName (a, b) {
      if (a.name < b.name) { return -1 }
      if (a.name > b.name) { return 1 }
      return 0
    },
    load () {
      this.selectableCategories = []
      this.services = []
      this.serviceGroups = []

      const { groupId } = this.$route.query
      if (!groupId) return

      this.loading = true

      Promise.all([
        groupApi.getServiceGroups(groupId),
        groupApi.getShortServices(groupId),
      ]).then(([serviceGroups, services]) => {
        this.serviceGroups = serviceGroups.data.sort(this.compareName)
        this.services = services.data.sort(this.compareName)
      }).finally(() => {
        this.loading = false
      })
    },
    selectAll (value) {
      for (const category of this.selectableCategories) {
        category.selected = value
      }
    },
    startFilter () {
      this.selectableCategories = this.categories.map(category => {
        return {
          ...category,
          selected: this.selectedCategoryIds.includes(category.id.toString()),
        }
      })
      this.$refs.modal.show()
    },
    onDoneFiltering () {
      this.$refs.modal.hide()

      this.selectedCategoryIds = this.selectableCategories.filter(category => category.selected).map(category => category.id.toString())
      const location = {
        path: this.$route.path,
        query: {
          ...this.$route.query,
          serviceGroups: this.useGroups ? this.selectedCategoryIds : [],
          services: this.useGroups ? [] : this.selectedCategoryIds,
        },
      }
      this.$router.replace(location)
    },
  },
}
</script>
