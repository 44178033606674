<template>
  <div class="d-flex align-items-center">
    <div class="font-weight-bold mr-2">
      States:
    </div>
    <div class="d-flex align-items-center rounded border">
      <!--State Toggle Button -->
      <div
        v-for="(state, index) in availableStates"
        :key="state"
        v-tooltip="{
          content: 'Projects that were in ' + state + ' state at the end of the period.',
          placement: 'top-center',
          offset: '5',
        }"
        class="hoverable p-2"
        :class="index > 0 ? 'border-left' : ''"
        style="cursor: pointer;"
        @click="toggleSelectedState(state)"
      >
        <!-- State Icon -->
        <span
          :style="{ color: selectedStates.includes(state) ? stateColors[state] : 'lightgrey' }"
          style="opacity: 0.8;"
        >&#10687;</span>
        <!-- State Name -->
        <span
          class="text-capitalize"
          :class="selectedStates.includes(state) ? '' : 'text-gray-600'"
        >{{ state }}</span>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data () {
    return {
      loading: true,
      stateColors: {
        'Quoted': 'darkorange',
        'Open': 'mediumseagreen',
        'Pending Closure': 'dodgerblue',
        'Closed': 'indianred',
      },
      availableStates: ['Quoted', 'Open', 'Pending Closure', 'Closed'],
    }
  },
  computed: {
    selectedStates: {
      get () {
        return this.$route.query.states
      },
      set (value) {
        const location = {
          path: this.$route.path,
          query: { ...this.$route.query, states: value },
        }
        this.$router.replace(location)
      },
    },
  },
  watch: {
    '$route' () {
      this.setDefaultStates()
    },
  },
  beforeMount () {
    this.setDefaultStates()
  },
  methods: {
    setDefaultStates () {
      if (!this.selectedStates) {
        this.selectedStates = ['Open', 'Pending Closure', 'Closed']
      }
    },
    toggleSelectedState (state) {
      const index = this.selectedStates.indexOf(state)
      if (index === -1) {
        this.selectedStates = this.selectedStates.concat([state])
      } else {
        if (this.selectedStates.length === 1) return
        this.selectedStates = this.selectedStates.filter(s => s !== state)
      }
    },
  },
}
</script>
