<template>
  <div>
    <!-- Header -->
    <ReportHeader
      title="General Financial Overview"
      period-filter
      state-filter
      team-filter
      class="mb-3"
      @filterChanged="load"
    />
    <!-- Loading Indicator-->
    <div
      v-if="loading"
      class="d-flex align-items-center justify-content-center p-5"
    >
      <div class="spinner-border" />
    </div>
    <!-- No records indicator -->
    <div
      v-else-if="projects.length === 0"
      class="d-flex justify-content-center lead text-gray-600 p-5"
    >
      No Records Found
    </div>
    <!-- Report Data -->
    <div
      v-else
      class="row align-items-baseline"
    >
      <!-- Group Information-->
      <div
        v-for="group in projectsByGroup"
        :key="group.id"
        class="col p-0 m-1 shadow-sm rounded-lg bg-light border "
        style="max-width: 500px;"
      >
        <div class="bg-white shadow-sm">
          <!-- General Information -->
          <div class="d-flex p-4">
            <div class="flex-grow-1">
              <!-- Group Name -->
              <div class="h4 font-weight-bold">
                {{ group.name }}
              </div>
              <!-- Projects Count -->
              <div class="text-gray-600 mb-3">
                {{ group.count }} Projects
              </div>
            </div>
            <!-- Total Amount -->
            <div>
              <div class="h5 font-weight-bold text-white bg-info rounded-pill px-3 py-1">
                {{ formatCurrency(group.total) }}
              </div>
            </div>
          </div>
          <!-- State-Specific Information -->
          <div
            v-for="(state, index) in group.states"
            :key="index"
            class="bg-gray-100"
          >
            <!-- Divider -->
            <div
              style="border-top: solid 1px #e0e0e0;"
            />
            <!-- State Summary-->
            <div
              class="d-flex px-4 py-3 hoverable"
              style="cursor: pointer;"
              @click="toggleExpandedState(group.name + state.name)"
            >
              <div class="flex-grow-1">
                <div class="text-gray-600 text-capitalize">
                  <!-- State Icon -->
                  <span
                    :style="{ color: stateColors[state.name] }"
                    style="opacity: 0.8;"
                  >&#10687;</span>&nbsp;
                  <!-- State Name -->
                  <span :class="expandedStates.includes(group.name + state.name) ? 'font-weight-bold' : ''">{{ state.name }}</span>&nbsp;
                  <!-- Projects Count -->
                  <span
                    v-if="state.projects.length > 0"
                    class="text-gray-500"
                  >({{ state.projects.length }})</span>
                </div>
              </div>
              <!-- State Total Amount -->
              <div
                class=" text-gray-600  text-right"
                :class="expandedStates.includes(group.name + state.name) ? 'font-weight-bold' : ''"
              >
                {{ formatCurrency(state.total) }}
              </div>
            </div>
            <!-- Projects -->
            <div
              v-show="expandedStates.includes(group.name + state.name)"
              class="py-3 px-4"
            >
              <!-- Empty List -->
              <div
                v-if="state.projects.length === 0"
                class="d-flex align-items-center justify-content-center text-gray-500 p-3"
              >
                No Projects
              </div>
              <!-- List -->
              <div v-else>
                <div
                  v-for="project in state.projects"
                  :key="group.name + '.' + project.project_id"
                >
                  <div class="d-flex mb-2">
                    <div class="flex-grow-1 d-flex ">
                      <!-- Project State Icon -->
                      <div
                        class="text-gray-400"
                        style="opacity: 0.5;"
                        :style="{ color: stateColors[state.name] }"
                      >
                        &#10687;&nbsp;&nbsp;&nbsp;
                      </div>
                      <!-- Project Id -->
                      <router-link
                        class="text-gray-600"
                        :to="{ name: 'show_project_info', params: { projectId: project.project_id } }"
                      >
                        Project {{ project.project_id }}
                      </router-link>
                    </div>
                    <!-- Amount -->
                    <div class="text-gray-600">
                      {{ formatCurrency(project.project_price) }}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- Download Buttons -->
    <ReportDownloadButtons
      csv-filename="general_financial_report.csv"
      :csv-fields="csvFields"
      :csv-data="csvData"
    />
    <!-- Errors -->
    <div
      v-if="error"
      class="alert alert-danger"
    >
      {{ error }}
    </div>
  </div>
</template>

<script>
import Enumerable from 'linq'
import reportAPI from '@/api/report'
import ReportHeader from './ReportHeader'
import ReportDownloadButtons from './ReportDownloadButtons'
import formatCurrency from '@/filters/formatCurrency'
import formatDateTime from '@/filters/formatDateTime'

export default {
  components: {
    ReportHeader,
    ReportDownloadButtons,
  },
  data () {
    return {
      loading: false,
      projects: [],
      stateColors: {
        'Quoted': 'darkorange',
        'Open': 'mediumseagreen',
        'Pending Closure': 'dodgerblue',
        'Closed': 'indianred',
      },
      expandedStates: [],
      csvFields: ['Project ID', 'Project Name', 'Project Price', 'Created On', 'Project Type ID', 'Project Type Name'],
      csvData: [],
      error: '',
    }
  },
  computed: {
    projectsByGroup () {
      return Enumerable
        .from(this.projects)
        .groupBy(project => project.project_type_id)
        .select(group => {
          const states = []
          this.selectedStates.forEach(state => {
            states.push({
              name: state,
              total: group.where(project => project.project_state_name === state).sum(project => project.project_price),
              projects: group.where(project => project.project_state_name === state).toArray(),
              expanded: false,
            })
          })
          return {
            name: group.first().project_type_name,
            states,
            total: group.sum(x => x.project_price),
            count: group.count(),
          }
        })
        .toArray()
    },
    selectedStates () {
      return this.$route.query.states || []
    },
  },
  methods: {
    load () {
      this.projects = []
      this.loading = true

      const { teamId, startDate, endDate, states } = this.$route.query

      reportAPI.getFinancialOverviewReport(teamId, startDate, endDate, states).then(resp => {
        this.projects = resp.data
        this.loading = false
        this.loadCsv()
      }).catch(error => {
        this.projects = []
        this.error = error.message
        this.loading = false
      })
    },
    toggleExpandedState (stateKey) {
      const index = this.expandedStates.indexOf(stateKey)
      if (index === -1) {
        this.expandedStates.push(stateKey)
      } else {
        this.expandedStates.splice(index, 1)
      }
    },
    loadCsv () {
      const csv = []
      for (let i = 0; i < this.projects.length; i++) {
        const proj = []
        proj.push(this.projects[i].project_id)
        proj.push(this.projects[i].project_name)
        proj.push(this.projects[i].project_price)
        proj.push(this.formatDateTime(this.projects[i].project_state_created_on, 'YYYY-MM-DD'))
        proj.push(this.projects[i].project_type_id)
        proj.push(this.projects[i].project_type_name)
        csv.push(proj)
      }
      this.csvData = csv
    },
    formatCurrency,
    formatDateTime,
  },
}
</script>
