<template>
  <div class="bg-gray-200 px-3 py-2 rounded-lg">
    <!-- Loading Indicator -->
    <span v-if="loading">
      <div class="spinner-border spinner-border-sm text-primary" />
    </span>
    <!-- Groups Dropdown -->
    <span
      v-else-if="reportGroups.length <= 1"
      class="text-primary font-weight-bold pointer lead"
    >
      {{ reportGroups.length === 0 ? 'No Groups Available' : selectedGroup.name || 'Unknown Group' }}
    </span>
    <div
      v-else
      class="dropdown"
    >
      <div
        class="dropdown-toggle text-primary font-weight-bold pointer lead"
        data-toggle="dropdown"
        style="cursor: pointer;"
      >
        {{ selectedGroup.name || 'Unknown Group' }}
      </div>
      <div class="dropdown-menu">
        <div
          v-for="group in reportGroups"
          :key="group.id"
          class="dropdown-item lead pointer"
          @click="selectedGroupId = group.id"
        >
          {{ group.name }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import groupAPI from '@/api/group'

export default {
  props: {
    requireSubCategories: {
      type: Boolean,
      default: false,
    },
  },
  data () {
    return {
      loading: false,
      availableGroups: [],
    }
  },
  computed: {
    reportGroups () {
      return this.availableGroups
        .filter(g => !(this.requireSubCategories && !g.use_subcats))
        .filter(g =>
          this.currentUser.is_admin ||
                        this.currentUser.roles.findIndex(
                          r => (r.name === 'Report' || r.name === 'Admin') && r.project_type_id === g.id) >= 0,
        )
    },
    selectedGroup () {
      return this.reportGroups.filter(group => group.id.toString() === this.selectedGroupId)[0] || {}
    },
    selectedGroupId: {
      get () {
        return this.$route.query.groupId
      },
      set (value) {
        this.$router.replace({
          path: this.$route.path,
          query: { ...this.$route.query, groupId: value.toString(), serviceGroups: [], services: [] },
        })
      },
    },
    ...mapState('user', ['currentUser']),
  },
  watch: {
    '$route' () {
      this.setDefaultGroup()
    },
  },
  beforeMount () {
    this.load()
  },
  methods: {
    load () {
      this.availableGroups = []
      this.loading = true
      groupAPI.getGroups().then((groups) => {
        this.availableGroups = groups.data
      }).catch(error => {
        console.log(error)
      }).finally(() => {
        this.loading = false
        this.setDefaultGroup()
      })
    },
    setDefaultGroup () {
      if (!this.selectedGroupId && this.reportGroups[0]) {
        this.selectedGroupId = this.reportGroups[0].id
      }
    },
  },
}
</script>
