<template>
  <div>
    <!-- Loading Indicator -->
    <div v-if="loading">
      <div class="spinner-border spinner-border-sm" />
    </div>
    <!-- Teams Filter -->
    <div
      v-else
      class="d-flex align-items-center"
    >
      <!-- Label -->
      <div class="font-weight-bold mr-2">
        Team:
      </div>
      <!-- Teams Dropdown -->
      <div
        class="dropdown "
      >
        <div
          class="dropdown-toggle font-weight-bold form-control"
          :class="{ 'text-primary': !!selectedTeamId }"
          data-toggle="dropdown"
          style="cursor: pointer;"
        >
          {{ teams.length === 0 ? 'No Teams Available' : selectedTeam.name || 'Unknown Team' }}
        </div>
        <div class="dropdown-menu">
          <div
            v-for="team in teams"
            :key="team.id"
            class="dropdown-item pointer"
            @click="selectedTeamId = team.id"
          >
            {{ team.name }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import teamAPI from '@/api/team'

export default {
  data () {
    return {
      loading: false,
      teams: [],
    }
  },
  computed: {
    selectedTeam () {
      if (!this.selectedTeamId) {
        return { name: 'All' }
      }
      return this.teams.find(team => team.id && team.id.toString() === this.selectedTeamId) || {}
    },
    selectedTeamId: {
      get () {
        return this.$route.query.teamId
      },
      set (value) {
        this.$router.replace({
          path: this.$route.path,
          query: { ...this.$route.query, teamId: value ? value.toString() : value },
        })
      },
    },
  },
  beforeMount () {
    this.load()
  },
  methods: {
    load () {
      this.teams = []
      this.loading = true

      teamAPI.getTeams().then(response => {
        this.teams = response.data.concat([{ name: 'All' }])
      }).finally(() => {
        this.loading = false
      })
    },
  },
}
</script>
