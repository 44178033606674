<template>
  <div>
    <!-- Header -->
    <ReportHeader
      title="Services Financial Overview"
      state-filter
      period-filter
      group-filter
      team-filter
      class="mb-4"
      @filterChanged="load"
    />
    <!-- Loading Indicator-->
    <div
      v-if="loading"
      class="d-flex align-items-center justify-content-center p-5"
    >
      <div class="spinner-border" />
    </div>
    <!-- No records indicator -->
    <div
      v-else-if="items.length === 0"
      class="d-flex justify-content-center lead text-gray-600 p-5"
    >
      No Records Found
    </div>
    <!-- Report Data -->
    <div v-else>
      <!-- Platforms -->
      <div
        v-for="(platform, index) in itemsByPlatform"
        :key="index"
        class="rounded-lg shadow-sm border bg-light mb-4"
      >
        <!-- Header -->
        <div
          class="d-flex bg-white text-info shadow-sm px-4 py-3"
          style="cursor: pointer;"
          @click="togglePlatform(platform)"
        >
          <!-- Title -->
          <h5 class="flex-grow-1 font-weight-bold m-0">
            {{ platform.title }}
          </h5>
          <!-- Amount  -->
          <div class="h5 font-weight-bold m-0">
            {{ formatCurrency(platform.totalPrice) }}
          </div>
          <div class="h5 my-0 mx-3">
            •
          </div>
          <!-- Count -->
          <div class="h5 text-right m-0">
            {{ platform.totalCount }} items
          </div>
        </div>
        <div v-show="!collapsedPlatforms.includes(platform)">
          <!-- Categories -->
          <div
            v-for="(category, categoryIndex) in platform.categories"
            :key="categoryIndex"
            class="m-5"
          >
            <!-- Header -->
            <div class="d-flex pr-3">
              <!-- Title -->
              <h6 class="flex-grow-1 font-weight-bold m-0 ">
                {{ category.title }}
              </h6>
              <!-- Amount -->
              <div class="h6 font-weight-bold">
                {{ formatCurrency(category.totalPrice) }}
              </div>
              <!-- Count -->
              <div style="width: 100px;">
                <div class="h6 text-right m-0">
                  {{ category.totalCount }} items
                </div>
              </div>
            </div>
            <!-- Sub Categories -->
            <div
              v-for="(subCategory, subCategoryIndex) in category.subCategories"
              :key="subCategoryIndex"
            >
              <!-- Divider -->
              <div
                class="bg-white"
                style="height: 1px;"
              />
              <div class="subcategory pr-3 py-1">
                <!-- Header -->
                <div class="d-flex text-gray-600">
                  <!-- Title -->
                  <div class="flex-grow-1">
                    {{ subCategory.title }}
                  </div>
                  <!-- Amount -->
                  <div class="font-weight-bold">
                    {{ formatCurrency(subCategory.totalPrice) }}
                  </div>
                  <!-- Count -->
                  <div style="width: 100px;">
                    <div class="text-right">
                      {{ subCategory.totalCount }} items
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- Download Buttons -->
    <ReportDownloadButtons
      csv-filename="services_financial_report.csv"
      :csv-fields="csvFields"
      :csv-data="csvData"
    />
    <div
      v-if="error"
      class="alert alert-danger"
    >
      {{ error }}
    </div>
  </div>
</template>

<script>
import Enumerable from 'linq'
import api from '@/api/report'
import ReportHeader from './ReportHeader'
import ReportDownloadButtons from './ReportDownloadButtons'
import formatCurrency from '@/filters/formatCurrency'

export default {
  components: {
    ReportHeader,
    ReportDownloadButtons,
  },
  data () {
    return {
      items: [],
      csvFields: ['Category ID', 'Category Name', 'Category Platform', 'Subcategory ID', 'Subcategory Name', 'Subcategory Description', 'Total Count', 'Total Price'],
      csvData: [],
      error: '',
      loading: false,
      collapsedPlatforms: [],
    }
  },
  computed: {
    itemsByPlatform () {
      return Enumerable
        .from(this.items)
        .groupBy(item => item.category_platform)
        .select(platform => {
          return {
            title: platform.key(),
            totalPrice: platform.sum(item => item.total_price),
            totalCount: platform.sum(item => item.total_count),
            categories: platform
              .groupBy(item => item.category_id)
              .select(category => {
                return {
                  title: category.first().category_name,
                  totalPrice: category.sum(item => item.total_price),
                  totalCount: category.sum(item => item.total_count),
                  subCategories: category
                    .groupBy(item => item.subcategory_id)
                    .select(subCategory => {
                      return {
                        title: subCategory.first().subcategory_descr,
                        totalPrice: subCategory.sum(item => item.total_price),
                        totalCount: subCategory.sum(item => item.total_count),
                      }
                    })
                    .toArray(),
                }
              })
              .toArray(),
          }
        })
        .toArray()
    },
  },
  methods: {
    load () {
      this.items = []
      this.loading = true

      const { groupId, teamId, startDate, endDate, states } = this.$route.query

      api.getServicesOverviewReport(groupId, teamId, startDate, endDate, states).then(response => {
        this.items = response.data
        this.loadCsv()
        this.loading = false
      }).catch(error => {
        this.items = []
        this.error = error.message
        this.loading = false
      })
    },
    togglePlatform (platform) {
      const index = this.collapsedPlatforms.indexOf(platform)
      if (index === -1) {
        this.collapsedPlatforms.push(platform)
      } else {
        this.collapsedPlatforms.splice(index, 1)
      }
    },
    loadCsv () {
      const csv = []
      for (let i = 0; i < this.items.length; i++) {
        const item = []
        item.push(this.items[i].category_id)
        item.push(this.items[i].category_name)
        item.push(this.items[i].category_platform)
        item.push(this.items[i].subcategory_id)
        item.push(this.items[i].subcategory_name)
        item.push(this.items[i].subcategory_descr)
        item.push(this.items[i].total_count)
        item.push(this.items[i].total_price)
        csv.push(item)
      }
      this.csvData = csv
    },
    formatCurrency,
  },
}
</script>

<style scoped>
    .subcategory:hover {
        background-color: white;
    }
</style>
