<template>
  <div
    class="d-flex align-items-center"
  >
    <!-- Label -->
    <div class="font-weight-bold mr-2">
      Week:
    </div>
    <!-- Input -->
    <div style="margin-bottom: -12px;">
      <BaseInputNumber
        v-model="week"
        :min="1"
        :max="53"
        name="year"
        title=""
        required
      />
    </div>
  </div>
</template>

<script>
import moment from 'moment'
import BaseInputNumber from '../../../components/forms/BaseInputNumber'

export default {
  components: {
    BaseInputNumber,
  },
  computed: {
    week: {
      get () {
        return this.$route.query.week
      },
      set (value) {
        const location = {
          path: this.$route.path,
          query: { ...this.$route.query, week: value },
        }
        this.$router.replace(location)
      },
    },
  },
  watch: {
    '$route' () {
      this.setDefaultWeek()
    },
  },
  beforeMount () {
    this.setDefaultWeek()
  },
  methods: {
    setDefaultWeek () {
      if (!this.week) {
        this.week = moment().isoWeek().toString()
      }
    },
  },
}
</script>
