<template>
  <div
    class="mb-3 shadow-sm border rounded-lg p-3 m-2"
    style="width: 300px; min-height: 150px;"
  >
    <h5 class="font-weight-bold pb-1">
      {{ title }} Summary
    </h5>
    <div
      v-if="loading"
      class="d-flex align-items-center justify-content-center p-4"
    >
      <div class="spinner-border" />
    </div>
    <div
      v-else-if="Object.keys(data).length === 0"
      class="text-center py-3"
    >
      No Results
    </div>
    <table
      v-else
      class="w-100"
    >
      <thead>
        <th>{{ title }}</th>
        <th class="text-right">
          Count
        </th>
      </thead>
      <tbody>
        <tr
          v-for="(row, index) in data"
          :key="index"
        >
          <td v-if="row[0] === ''">
            &nbsp;
          </td>
          <td v-else>
            {{ row[0] }}
          </td>
          <td class="text-right">
            {{ row[1] }}
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
export default {
  props: {
    title: {
      type: String,
      required: true,
    },
    data: {
      type: Array,
      required: true,
    },
    loading: {
      type: Boolean,
      default: false,
    },
  },
}
</script>
