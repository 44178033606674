<template>
  <!-- Main content -->
  <div>
    <!-- Report Header -->
    <ReportHeader
      title="Year Report"
      group-filter
      team-filter
      year-filter
      @filterChanged="load"
    />
    <!-- Report Content -->
    <div class="row">
      <div class="col-md-12">
        <!-- Report Tabs start -->
        <ul class="nav nav-tabs nav-justified">
          <li class="nav-item">
            <a
              class="nav-link active"
              data-toggle="tab"
              data-target="#allReports"
            >View All</a>
          </li>
          <li class="nav-item">
            <a
              class="nav-link"
              data-toggle="tab"
              data-target="#weekReport"
            >Week/Quarterly Report</a>
          </li>
          <li class="nav-item">
            <a
              class="nav-link"
              data-toggle="tab"
              data-target="#openClosedReport"
            >Open VS Closed</a>
          </li>
          <li class="nav-item">
            <a
              class="nav-link"
              data-toggle="tab"
              data-target="#SLOReport"
            >SLO report</a>
          </li>
        </ul>
        <!-- Report Tabs end -->

        <!-- Reports start -->
        <div class="tab-content">
          <div
            id="allReports"
            class="tab-pane fade show active"
          >
            <div v-show="displayReport">
              <div class="row">
                <div class="col-md-6">
                  <BaseCard>
                    <template #header>
                      <font-awesome-icon icon="briefcase" />
                      Week/Quarterly Report
                      <div
                        v-if="csvYearURL"
                        class="btn-group float-right"
                      >
                        <a
                          :href="csvYearURL"
                          download="year_report.csv"
                        >
                          <button class="btn btn-light btn-xs">
                            <font-awesome-icon icon="download" />
                            Download CSV
                          </button>
                        </a>
                      </div>
                    </template>
                    <table class="table table-striped quarterly-table">
                      <tbody>
                        <tr class="qt-header-row">
                          <th>Week #</th>
                          <th>New</th>
                          <th>Quoted</th>
                          <th>Open</th>
                          <th>Work in Progress</th>
                          <th>Pending Closure</th>
                          <th>Closed</th>
                          <th>Rejected</th>
                        </tr>
                        <template v-for="p in report">
                          <!-- eslint-disable-next-line vue/require-v-for-key -->
                          <tr
                            :class="{ 'qt-quarter-row': p.week === 'Q1' || p.week === 'Q2' || p.week === 'Q3' || p.week === 'Q4',
                                      'qt-total-row': p.week === 'Total',
                                      'qt-complete-row': p.open === p.closed && p.open !== 0 }"
                          >
                            <td class="week-column">
                              {{ p.week }}
                            </td>
                            <td>{{ p.new }}</td>
                            <td>{{ p.quoted }}</td>
                            <td>{{ p.open }}</td>
                            <td>{{ p.work_in_progress }}</td>
                            <td>{{ p.pending_closure }}</td>
                            <td>{{ p.closed }}</td>
                            <td>{{ p.rejected }}</td>
                          </tr>
                        </template>
                      </tbody>
                    </table>
                  </BaseCard>
                </div>

                <div class="col-md-6">
                  <BaseCard>
                    <template #header>
                      <font-awesome-icon icon="chart-line" />
                      Open versus Closed
                      <div
                        v-if="csvOpenClosedURL"
                        class="btn-group float-right ml-2"
                      >
                        <a
                          :href="csvOpenClosedURL"
                          download="open_closed_report.csv"
                        >
                          <button class="btn btn-light btn-xs">
                            <font-awesome-icon icon="download" />
                            Download CSV
                          </button>
                        </a>
                      </div>
                      <div class="float-right">
                        <span>Accumulate</span>
                        <div class="btn-group">
                          <button
                            :class="{ 'btn': true, 'btn-xs': true, 'btn-primary': accOpenClosed, 'btn-default': !accOpenClosed }"
                            @click.prevent="setOCAccumulate(true)"
                          >
                            ON
                          </button>
                          <button
                            :class="{ 'btn': true, 'btn-xs': true, 'btn-primary': !accOpenClosed, 'btn-default': accOpenClosed }"
                            @click.prevent="setOCAccumulate(false)"
                          >
                            OFF
                          </button>
                        </div>
                      </div>
                    </template>
                    <LineChart
                      :options="ocOptions"
                      :chart-data="ocData"
                      :width="null"
                      :height="null"
                    />
                  </BaseCard>

                  <BaseCard>
                    <template #header>
                      <font-awesome-icon icon="chart-bar" />
                      SLO per Category
                      <div
                        v-if="csvSLOURL"
                        class="btn-group float-right"
                      >
                        <a
                          :href="csvSLOURL"
                          download="slo_report.csv"
                        >
                          <button class="btn btn-light btn-xs">
                            <font-awesome-icon icon="download" />
                            Download CSV
                          </button>
                        </a>
                      </div>
                    </template>
                    <BarChart
                      :options="categoryOptions"
                      :chart-data="categoryData"
                      :width="null"
                      :height="null"
                    />
                  </BaseCard>
                </div>
              </div>
            </div>
          </div>

          <div
            id="weekReport"
            class="tab-pane fade"
          >
            <div v-show="displayReport">
              <BaseCard>
                <template #header>
                  <font-awesome-icon icon="briefcase" />
                  Week/Quarterly Report
                  <div
                    v-if="csvYearURL"
                    class="btn-group float-right"
                  >
                    <a
                      :href="csvYearURL"
                      download="year_report.csv"
                    >
                      <button class="btn btn-light btn-xs">
                        <font-awesome-icon icon="download" />
                        Download CSV
                      </button>
                    </a>
                  </div>
                </template>
                <table class="table table-striped quarterly-table">
                  <tbody>
                    <tr class="qt-header-row">
                      <th>Week #</th>
                      <th>New</th>
                      <th>Quoted</th>
                      <th>Open</th>
                      <th>Work in Progress</th>
                      <th>Pending Closure</th>
                      <th>Closed</th>
                      <th>Rejected</th>
                    </tr>
                    <template v-for="p in report">
                      <!-- eslint-disable-next-line vue/require-v-for-key -->
                      <tr
                        :class="{ 'qt-quarter-row': p.week === 'Q1' || p.week === 'Q2' || p.week === 'Q3' || p.week === 'Q4',
                                  'qt-total-row': p.week === 'Total',
                                  'qt-complete-row': p.open === p.closed && p.open !== 0 }"
                      >
                        <td class="week-column">
                          {{ p.week }}
                        </td>
                        <td>{{ p.new }}</td>
                        <td>{{ p.quoted }}</td>
                        <td>{{ p.open }}</td>
                        <td>{{ p.work_in_progress }}</td>
                        <td>{{ p.pending_closure }}</td>
                        <td>{{ p.closed }}</td>
                        <td>{{ p.rejected }}</td>
                      </tr>
                    </template>
                  </tbody>
                </table>
              </BaseCard>
            </div>
          </div>

          <div
            id="openClosedReport"
            class="tab-pane fade"
          >
            <div v-show="displayReport">
              <BaseCard>
                <template #header>
                  <font-awesome-icon icon="chart-line" />
                  Open versus Closed
                  <div
                    v-if="csvOpenClosedURL"
                    class="btn-group float-right ml-2"
                  >
                    <a
                      :href="csvOpenClosedURL"
                      download="open_closed_report.csv"
                    >
                      <button class="btn btn-light btn-xs">
                        <font-awesome-icon icon="download" />
                        Download CSV
                      </button>
                    </a>
                  </div>
                  <div class="float-right">
                    <span>Accumulate</span>
                    <div class="btn-group">
                      <button
                        :class="{ 'btn': true, 'btn-xs': true, 'btn-primary': accOpenClosed, 'btn-default': !accOpenClosed }"
                        @click.prevent="setOCAccumulate(true)"
                      >
                        ON
                      </button>
                      <button
                        :class="{ 'btn': true, 'btn-xs': true, 'btn-primary': !accOpenClosed, 'btn-default': accOpenClosed }"
                        @click.prevent="setOCAccumulate(false)"
                      >
                        OFF
                      </button>
                    </div>
                  </div>
                </template>
                <LineChart
                  :options="ocOptions"
                  :chart-data="ocData"
                  :width="null"
                  :height="null"
                />
              </BaseCard>
            </div>
          </div>

          <div
            id="SLOReport"
            class="tab-pane fade"
          >
            <div v-show="displayReport">
              <BaseCard>
                <template #header>
                  <font-awesome-icon icon="chart-bar" />
                  SLO per Category
                  <div
                    v-if="csvSLOURL"
                    class="btn-group float-right"
                  >
                    <a
                      :href="csvSLOURL"
                      download="slo_report.csv"
                    >
                      <button class="btn btn-light btn-xs">
                        <font-awesome-icon icon="download" />
                        Download CSV
                      </button>
                    </a>
                  </div>
                </template>
                <BarChart
                  :options="categoryOptions"
                  :chart-data="categoryData"
                  :width="null"
                  :height="null"
                />
              </BaseCard>
            </div>
          </div>
        </div>
        <!-- Reports end -->
      </div>
    </div>
  </div>
</template>

<script>
/**
 * View to show Year report
*/
import BarChart from './BarChart.js'
import LineChart from './LineChart.js'
import reportAPI from '@/api/report'
import ReportHeader from './ReportHeader'

export default {
  components: {
    BarChart,
    LineChart,
    ReportHeader,
  },
  data () {
    return {
      accOpenClosed: false,
      displayReport: false,
      report: [],
      lineGraphData: [],
      barGraphData: [],
      lineGraph: null,
      lineGraph2: null,
      barGraph: null,
      barGraph2: null,
      teamSelect: undefined,
      projectTeams: [],
      csvYearData: null,
      csvYearURL: null,
      csvOpenClosedData: null,
      csvOpenClosedURL: null,
      csvSLOData: null,
      csvSLOURL: null,
      categoryOptions: {
        legend: {
          position: 'bottom',
        },
        tooltips: {
          callbacks: {
            label: this.tooltipLabel,
          },
        },
      },
      categoryData: {
        labels: [],
        datasets: [],
      },
      ocOptions: {
        responsive: true,
        maintainAspectRatio: !this.isMobile,
        legend: {
          position: 'bottom',
        },
        tooltips: {
          mode: 'label',
          xPadding: 10,
          yPadding: 10,
          bodySpacing: 10,
        },
        scales: {
          xAxes: [{
            ticks: {
              maxTicksLimit: 12,
            },
            scaleLabel: {
              display: true,
              labelString: 'Month',
              fontStyle: 'bold',
              fontSize: 14,
            },
          }],
          yAxes: [{
            ticks: {
              beginAtZero: true,
              // skip non-integer ticks
              callback: function (value) { if (value % 1 === 0) { return value } },
            },
          }],
        },
      },
      ocData: {
        labels: [],
        datasets: [],
      },
      error: '',
    }
  },
  computed: {
    isMobile () {
      return (window.innerWidth <= 800 && window.innerHeight <= 600)
    },
  },
  methods: {
    /**
     * @description updates graph to accumulative values
     * @params Boolean
    */
    setOCAccumulate (v) {
      if (v === this.accOpenClosed) {
        return
      }
      if (this.accOpenClosed) {
        this.deaccumulate(this.lineGraphData.open)
        this.deaccumulate(this.lineGraphData.closed)
      } else {
        this.accumulate(this.lineGraphData.open)
        this.accumulate(this.lineGraphData.closed)
      }
      this.accOpenClosed = !this.accOpenClosed
      // create new data object so graph gets refreshed
      this.ocData = Object.assign({}, this.ocData)
    },
    /**
     * @description accumulates values
     * @param lineGraphData
    */
    accumulate (arr) {
      for (let i = 1; i < arr.length; i++) {
        arr[i] = arr[i] + arr[i - 1]
      }
    },
    /**
     * @description deaccumulates values
     * @param lineGraphData
    */
    deaccumulate (arr) {
      for (let i = arr.length; i > 0; i--) {
        arr[i] = arr[i] - arr[i - 1]
      }
    },
    /**
     * @description get Year report data from database and generate reports
     * @params String, Int
    */
    load () {
      const { groupId, year, teamId } = this.$route.query

      reportAPI.getYearReport(groupId, year, teamId).then(resp => {
        this.report = resp.data
        const data = []
        const header = ['Week #', 'New', 'Quoted', 'Open', 'Work in Progress', 'Pending Closure', 'Closed', 'Rejected']
        data.push(header)

        const len = this.report.length
        for (let i = 0; i < len; i++) {
          const obj = []
          obj.push(this.report[i].week, this.report[i].new, this.report[i].quoted, this.report[i].open, this.report[i].work_in_progress, this.report[i].pending_closure, this.report[i].closed, this.report[i].rejected)
          data.push(obj)
        }
        this.csvYearData = new Blob([data.map(row => row.join(',')).join('\r\n')], { type: 'text/csv;charset=utf-8;' })
        if (!navigator.msSaveBlob) {
          this.csvYearURL = window.URL.createObjectURL(this.csvYearData)
        }
      })
      reportAPI.getOpenClosedReport(groupId, year, teamId).then(resp => {
        this.accOpenClosed = false
        this.lineGraphData = resp.data
        if (this.lineGraph) {
          this.lineGraph.destroy()
        }
        this.makeLineGraph()
        const data = []
        const months = this.lineGraphData.month
        const open = this.lineGraphData.open
        const closed = this.lineGraphData.closed
        months.unshift('State\\Month')
        open.unshift('Open')
        closed.unshift('Closed')
        data.push(months, open, closed)
        this.csvOpenClosedData = new Blob([data.map(row => row.join(',')).join('\r\n')], { type: 'text/csv;charset=utf-8;' })
        if (!navigator.msSaveBlob) {
          this.csvOpenClosedURL = window.URL.createObjectURL(this.csvOpenClosedData)
        }
        months.shift()
        open.shift()
        closed.shift()
      })
      reportAPI.getSLOReport(groupId, year, teamId).then(resp => {
        this.barGraphData = resp.data
        if (this.barGraph) {
          this.barGraph.destroy()
        }
        this.makeBarGraph()
        const category = this.barGraphData.categories
        const avg = this.barGraphData.average_lengths
        const numProjects = this.barGraphData.num_projects
        const target = this.barGraphData.target_slo
        category.unshift('Data\\Category')
        avg.unshift('Avg Length')
        numProjects.unshift('Num Projects')
        target.unshift('Target')
        const data = []
        data.push(category, avg, numProjects, target)
        this.csvSLOData = new Blob([data.map(row => row.join(',')).join('\r\n')], { type: 'text/csv;charset=utf-8;' })
        if (!navigator.msSaveBlob) {
          this.csvSLOURL = window.URL.createObjectURL(this.csvSLOData)
        }
        category.shift()
        avg.shift()
        numProjects.shift()
        target.shift()
      })
      this.displayReport = true
    },
    /**
     * @description generates line graph
    */
    makeLineGraph () {
      this.ocData = {
        labels: this.lineGraphData.month,
        datasets: [
          {
            label: 'Total',
            fill: false,
            borderColor: '#476ac7',
            pointBackgroundColor: '#476ac7',
            backgroundColor: 'rgba(0, 0, 0, 0)',
            data: this.lineGraphData.open,
          },
          {
            label: 'Closed',
            fill: false,
            borderColor: '#6ac747',
            pointBackgroundColor: '#6ac747',
            backgroundColor: 'rgba(0, 0, 0, 0)',
            data: this.lineGraphData.closed,
          },
        ],
      }
    },
    /**
     * @description generates bar graph
    */
    makeBarGraph () {
      this.categoryData = {
        labels: this.barGraphData.categories,
        datasets: [{
          label: 'Avg. Time',
          data: this.barGraphData.average_lengths,
          backgroundColor: '#f39c12'.replace(/1\)$/, '.5)'),
        }, {
          label: 'Target Time',
          data: this.barGraphData.target_slo,
          backgroundColor: '#4286f4'.replace(/1\)$/, '.5)'),
        }],
      }
    },
    /**
     * @description downloads Report information as CSV file
     * @param String
    */
    downloadCSV (graphType) {
      if (graphType === 'year') {
        navigator.msSaveBlob(this.csvYearData, 'year_report.csv')
      } else if (graphType === 'openClosed') {
        navigator.msSaveBlob(this.csvOpenClosedData, 'open_closed_report.csv')
      } else if (graphType === 'SLO') {
        navigator.msSaveBlob(this.csvSLOData, 'slo_report.csv')
      }
    },
    /**
     * @description navigates to previous page
    */
    goBack () {
      this.$router.push('/')
    },
    tooltipLabel (tooltipItem, data) {
      let label = data.datasets[tooltipItem.datasetIndex].label || ''
      if (label) {
        label += ': '
      }
      label += Math.round(tooltipItem.yLabel * 10) / 10
      return label
    },
  },
}
</script>

<style scoped>
.quarterly-table {
  table-layout: fixed;
}
/* set table bg colour of row to blue with white text*/
.quarterly-table tr.qt-header-row th {
  background-color: #4286f4;
  color: white;
}
/* set table bg colour of row to blue */
.quarterly-table tr.qt-quarter-row td {
  background-color: #4286f4;
}
/* set table bg colour of row to green */
.quarterly-table tr.qt-total-row td,
.quarterly-table tr.qt-complete-row td {
  background-color: #6ae868;
}
/* set table column to orange */
.quarterly-table td.week-column {
  background-color: #f49e42;
}
/* set table column to orange */
.quarterly-table tr.qt-quarter-row td.week-column {
  background-color: #f49e42;
  text-align: center;
}
/* set table column to orange */
.quarterly-table tr.qt-complete-row td.week-column {
  background-color: #f49e42;
}
</style>
