<template>
  <div id="dashboard">
    <div class="mb-3">
      <ReportHeader
        title="Dashboard"
        period-filter
        group-filter
        team-filter
        @filterChanged="getData"
      />
    </div>
    <div class="row">
      <DashboardPanel
        :colour="state.Total.colour"
        :count="state.Total.count"
        :title="group.name + ' Total'"
      >
        <SigmaIcon title="" />
      </DashboardPanel>

      <DashboardPanel
        :colour="state.New.colour"
        :count="state.New.count"
        :title="group.name + ' New'"
      >
        <ClockStartIcon title="" />
      </DashboardPanel>

      <DashboardPanel
        :colour="state.Open.colour"
        :count="state.Open.count"
        :title="group.name + ' Open'"
      >
        <ClockFastIcon title="" />
      </DashboardPanel>

      <DashboardPanel
        :colour="state.Closed.colour"
        :count="state.Closed.count"
        :title="group.name + ' Closed'"
      >
        <ClockEndIcon title="" />
      </DashboardPanel>
    </div>

    <div class="row">
      <div class="col-lg-6">
        <BaseCard>
          <template #header>
            <font-awesome-icon icon="chart-bar" />
            {{ group.name }} Project By Category
          </template>
          <div>
            <BarChart
              :chart-data="categoryData"
              :options="categoryOptions"
              :width="null"
              :height="null"
            />
          </div>
        </BaseCard>
      </div>

      <div class="col-lg-6">
        <BaseCard>
          <template #header>
            <font-awesome-icon icon="chart-pie" />
            {{ group.name }} Project Status
          </template>
          <div>
            <DoughnutChart
              :chart-data="statusData"
              :options="statusOptions"
              :width="null"
              :height="null"
            />
          </div>
        </BaseCard>
      </div>
    </div>

    <div class="row">
      <DashboardPanel
        :colour="state.Closed.colour"
        :count="percentCompleted + '%'"
        :title="state.Closed.count + '/' + state.Total.count + ' Closed'"
      >
        <CalendarMultipleCheckIcon title="" />
      </DashboardPanel>
      <DashboardPanel
        :colour="state.Rejected.colour"
        :count="percentRejected + '%'"
        :title="state.Rejected.count + '/' + state.Total.count + ' Rejected'"
      >
        <ClipboardAlertIcon title="" />
      </DashboardPanel>
      <DashboardPanel
        :colour="state.Open.colour"
        :count="percentOpen + '%'"
        :title="state.Open.count + '/' + state.Total.count + ' Opened'"
      >
        <CalendarClockIcon title="" />
      </DashboardPanel>
    </div>
  </div>
  <!-- /.content -->
</template>

<script>
/**
 * Main Dashboard view showing projects summaries with charts and graphs.
 */
import moment from 'moment'
import BarChart from '@/views/Report/BarChart.js'
import DoughnutChart from '@/views/Report/DoughnutChart.js'
import groupAPI from '@/api/group'
import reportAPI from '@/api/report'
import SigmaIcon from 'vue-material-design-icons/Sigma.vue'
import ClockStartIcon from 'vue-material-design-icons/ClockStart.vue'
import ClockFastIcon from 'vue-material-design-icons/ClockFast.vue'
import ClockEndIcon from 'vue-material-design-icons/ClockEnd.vue'
import CalendarMultipleCheckIcon from 'vue-material-design-icons/CalendarMultipleCheck.vue'
import ClipboardAlertIcon from 'vue-material-design-icons/ClipboardAlert.vue'
import CalendarClockIcon from 'vue-material-design-icons/CalendarClock.vue'
import ReportHeader from '@/views/Report/ReportHeader'
import DashboardPanel from '@/views/Report/DashboardPanel'

export default {
  components: {
    BarChart,
    DoughnutChart,
    SigmaIcon,
    ClockStartIcon,
    ClockFastIcon,
    ClockEndIcon,
    CalendarMultipleCheckIcon,
    ClipboardAlertIcon,
    CalendarClockIcon,
    ReportHeader,
    DashboardPanel,
  },
  beforeRouteEnter (to, from, next) {
    if (!to.query || !to.query.startDate) {
      // set default start date to beginning of the year
      const startDate = moment().startOf('year').format('YYYY-MM-DD')
      next({ path: to.path, query: { ...to.query, startDate } })
    } else {
      next()
    }
  },
  beforeRouteUpdate (to, from, next) {
    if (!to.query || !to.query.startDate) {
      // set default start date to beginning of the year
      const startDate = moment().startOf('year').format('YYYY-MM-DD')
      next({ path: to.path, query: { ...to.query, startDate } })
    } else {
      next()
    }
  },
  data () {
    return {
      group: {
        name: 'IP Networks',
      },
      state: {
        Total: { count: 0, colour: 'rgb(94,181,225)' },
        New: { count: 0, colour: 'rgb(242,146,38)' },
        Rejected: { count: 0, colour: 'rgb(150,121,189)' },
        Open: { count: 0, colour: 'rgb(240,178,196)' },
        Closed: { count: 0, colour: 'rgb(165,216,87)' },
      },
      categoryDescr: {},
      defaultColours: [
        '#f39c12',
        '#e6805f',
        '#00a65a',
        '#b04012',
        '#11cc12',
        '#aa5511',
        '#f30012',
        '#ffce56',
        '#1fc8db',
        '#fce473',
        '#42afe3',
        '#ed6c63',
      ],
      categoryColours: {
        'NC1': 'rgb(39,104,169)',
        'EV1': 'rgb(242,212,116)',
        'NV1': 'rgb(121,189,157)',
        'NV2': 'rgb(210,80,80)',
        'O2': 'rgb(165,216,87)',
        'O3': 'rgb(242,146,38)',
        'O4': 'rgb(240,178,196)',
        'O5': 'rgb(150,121,189)',
        'F-Type': 'rgb(206,206,206)',
        'N1': 'rgb(190,220,227)',
      },
      stateColours: {
        'Draft': 'rgb(210,80,80)',
        'New': 'rgb(242,146,38)',
        'Assigned Team': 'rgb(206,206,206)',
        'Reject1': 'rgb(150,121,189)',
        'Quoted': 'rgb(39,104,169)',
        'Open': 'rgb(240,178,196)',
        'Pending Closure': 'rgb(121,189,157)',
        'Closed': 'rgb(165,216,87)',
      },
      categoryData: {
        labels: [],
        datasets: [],
      },
      categoryOptions: {
        onClick: this.onClickCategory,
        legend: {
          display: false,
        },
        scales: {
          yAxes: [{
            ticks: {
              beginAtZero: true,
              // skip non-integer ticks
              callback: function (value) { if (value % 1 === 0) { return value } },
            },
          }],
        },
        tooltips: {
          callbacks: {
            title: this.catTooltipTitle,
            label: this.tooltipLabel,
          },
        },
      },
      statusData: {
        labels: [],
        datasets: [],
      },
      statusOptions: {
        onClick: this.onClickStatus,
        legend: {
          position: 'bottom',
        },
        tooltips: {
          callbacks: {
            title: this.tooltipTitle,
            label: this.tooltipLabel,
          },
        },
      },
    }
  },
  computed: {
    percentCompleted () {
      if (this.state.Total.count === 0) {
        return 0
      } else {
        return Math.round(this.state.Closed.count / this.state.Total.count * 100)
      }
    },
    percentRejected () {
      if (this.state.Total.count === 0) {
        return 0
      } else {
        return Math.round(this.state.Rejected.count / this.state.Total.count * 100)
      }
    },
    percentOpen () {
      if (this.state.Total.count === 0) {
        return 0
      } else {
        return Math.round(this.state.Open.count / this.state.Total.count * 100)
      }
    },
  },
  methods: {
    getData () {
      const { groupId, teamId, startDate, endDate } = this.$route.query

      Promise.all([
        groupAPI.getGroup(groupId),
        reportAPI.getServiceSummaryReport(groupId, teamId, startDate, endDate),
        reportAPI.getStatusSummaryReport(groupId, teamId, startDate, endDate),
      ]).then(([group, barStats, pieStats]) => {
        this.group = group.data
        this.setBarStats(barStats.data)
        this.setPieStats(pieStats.data)
      }).catch(error => {
        console.log(error)
      })
    },
    onClickCategory (event, elements) {
      if (!elements || !elements[0]) {
        return
      }
      const element = elements[0]
      const route = {
        name: 'projects',
        query: {
          project_type_name: this.group.name,
          created_on_gte: this.$route.query.startDate,
          created_on_lt: this.$route.query.endDate,
        },
      }
      if (element._model.label === 'Unassigned') {
        route.query.services_is_null = ''
      } else {
        route.query.services_includes = element._model.label
      }
      this.$router.push(route)
    },
    onClickStatus (event, elements) {
      if (!elements || !elements[0]) {
        return
      }
      const element = elements[0]
      this.$router.push({
        name: 'projects',
        query: {
          project_type_name: this.group.name,
          project_state_name: element._model.label.toLowerCase(),
          created_on_gte: this.$route.query.startDate,
          created_on_lt: this.$route.query.endDate,
        },
      })
    },
    setBarStats (barStats) {
      const labels = []
      const data = []
      const colours = []
      const defaultColours = this.defaultColours.slice()
      this.categoryDescr = {}
      barStats.forEach(s => {
        labels.push(s.category)
        data.push(s.count)
        this.categoryDescr[s.category] = s.descr
        // find colour for category
        let colour = s.colour
        if (!colour) {
          colour = this.categoryColours[s.category]
        }
        if (!colour) {
          // no assigned colour, allocate one from default colours
          colour = defaultColours.pop()
        }
        colours.push(colour)
      })
      this.categoryData = {
        labels: labels,
        datasets: [{ data: data, backgroundColor: colours }],
      }
    },
    setPieStats (pieStats) {
      const labels = []
      const data = []
      const colours = []
      const defaultColours = this.defaultColours.slice()
      this.state.Total.count = 0
      this.state.New.count = 0
      this.state.Rejected.count = 0
      this.state.Open.count = 0
      this.state.Closed.count = 0
      pieStats.forEach(s => {
        this.state.Total.count += s.count
        if (s.state === 'Reject1' || s.state === 'Reject2') {
          this.state.Rejected.count += s.count
        }
        labels.push(this.capitalize(s.state))
        data.push(s.count)
        // find colour for state
        s.colour = s.colour || this.stateColours[s.state]
        if (!s.colour) {
          // no assigned colour, allocate one from default colours
          s.colour = defaultColours.pop()
        }
        colours.push(s.colour)
        this.state[s.state] = s
      })
      this.statusData = {
        labels: labels,
        datasets: [{ data: data, backgroundColor: colours }],
      }
    },
    capitalize (s) {
      return s.replace(/\b\w/g, l => l.toUpperCase())
    },
    tooltipTitle (tooltipItems, data) {
      const index = tooltipItems[0].index
      return data.labels[index]
    },
    catTooltipTitle (tooltipItems, data) {
      const index = tooltipItems[0].index
      return this.categoryDescr[data.labels[index]]
    },
    tooltipLabel (tooltipItem, data) {
      const dataset = data.datasets[tooltipItem.datasetIndex]
      const total = dataset.data.reduce(function (previousValue, currentValue, currentIndex, array) {
        return previousValue + currentValue
      })
      const currentValue = dataset.data[tooltipItem.index]
      const percentage = Math.floor(((currentValue / total) * 100) + 0.5)
      return ' ' + currentValue + ' (' + percentage + '%)'
    },
  },
}
</script>

<style lang="scss" scoped>
#dashboard {
  // fix size and alignment of icons
  .material-design-icon {
    font-size: 48px;
    bottom: 0.125em;
  }
}
</style>
