<template>
  <div>
    <div
      class="d-flex align-items-center"
    >
      <!-- Label -->
      <div class="font-weight-bold mr-2">
        {{ title }}:
      </div>
      <!-- Dropdown -->
      <div
        class="dropdown "
      >
        <div
          class="dropdown-toggle form-control"
          :class="{ 'text-primary': selectedOption !== defaultValue }"
          data-toggle="dropdown"
          style="cursor: pointer;"
        >
          {{ selectedOption }}
        </div>
        <div class="dropdown-menu">
          <div
            v-for="opt in options"
            :key="opt"
            class="dropdown-item pointer"
            @click="selectedOption = opt"
          >
            {{ opt }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    title: {
      type: String,
      required: true,
    },
    options: {
      type: Array,
      required: true,
    },
    routeParam: {
      type: String,
      required: true,
    },
    defaultValue: {
      type: String,
      default: '',
    },
  },
  data () {
    return {
      loading: false,
      teams: [],
    }
  },
  computed: {
    selectedOption: {
      get () {
        return this.$route.query[this.routeParam] || this.defaultValue
      },
      set (value) {
        const query = { ...this.$route.query }
        if (value && value !== this.defaultValue) {
          query[this.routeParam] = value
        } else {
          delete query[this.routeParam]
        }
        this.$router.replace({ path: this.$route.path, query: query })
      },
    },
  },
}
</script>
