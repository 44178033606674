import axios from '@/axios/default'

export default {
  getPlatformReport (groupID, teamId) { return axios.get('/project_types/' + groupID + '/domain_report', { params: { teamId } }) },
  getWeekReport (groupID, week, year, teamId) { return axios.get('/project_types/' + groupID + '/week_report', { params: { week, year, teamId } }) },
  getYearReport (groupID, year, teamId) { return axios.get('/project_types/' + groupID + '/year_report', { params: { year, teamId } }) },
  getOpenClosedReport (groupID, year, teamId) { return axios.get('/project_types/' + groupID + '/open_closed_report', { params: { year, teamId } }) },
  getSLOReport (groupID, year, teamId) { return axios.get('/project_types/' + groupID + '/slo_report', { params: { year, teamId } }) },
  getFinancialOverviewReport (teamId, startDate, endDate, states) { return axios.get('/reports/financial_overview', { params: { teamId, startDate, endDate, states } }) },
  getServicesOverviewReport (groupID, teamId, startDate, endDate, states) { return axios.get('/project_types/' + groupID + '/services_report', { params: { teamId, startDate, endDate, states } }) },
  getStateDurationReport (groupID, teamId, startDate, endDate, serviceGroups, services) { return axios.get('/project_types/' + groupID + '/state_duration_report', { params: { teamId, startDate, endDate, serviceGroups, services } }) },
  getStateMetricReport (groupID, teamId, startState, endState, startDate, endDate, serviceGroups, services) { return axios.get('/project_types/' + groupID + '/state_metric_report', { params: { teamId, startState, endState, startDate, endDate, serviceGroups, services } }) },
  getServiceSummaryReport (groupID, teamId, startDate, endDate) { return axios.get('/project_types/' + groupID + '/service_summary_report', { params: { teamId, startDate, endDate } }) },
  getStatusSummaryReport (groupID, teamId, startDate, endDate) { return axios.get('/project_types/' + groupID + '/status_summary_report', { params: { teamId, startDate, endDate } }) },
}
