<template>
  <div class="col-md-3 col-sm-6 col-xs-12">
    <div
      class="rounded text-right text-white"
      style="padding: 10px 20px 10px 20px; margin-bottom: 20px;"
      :style="{ 'background-color': colour }"
    >
      <div
        class="d-flex justify-content-between"
        style="font-size: 40px;"
      >
        <slot />
        <div>{{ count }}</div>
      </div>
      <div>{{ title }}</div>
    </div>
  </div>
</template>

<script>

export default {
  props: {
    colour: {
      type: String,
      required: true,
    },
    count: {
      type: [String, Number],
      required: true,
    },
    title: {
      type: String,
      required: true,
    },
  },
}
</script>
