<template>
  <div>
    <!-- Header -->
    <ReportHeader
      title="Projects Status Monthly Trend"
      group-filter
      category-filter
      period-filter
      :period-filter-options="{ presets: presets }"
      team-filter
      class="mb-3"
      @filterChanged="load"
    />
    <!-- Loading Indicator-->
    <div
      v-if="loading"
      class="d-flex align-items-center justify-content-center p-5"
    >
      <div class="spinner-border" />
    </div>
    <!-- Report Chart -->
    <div
      v-else
      class="bg-light rounded-lg p-4"
    >
      <LineChart
        :chart-data="chartData"
        :options="chartOptions"
        :width="null"
        :height="null"
        style="height: calc(100vh - 320px);"
      />
    </div>
  </div>
</template>

<script>
import ReportHeader from './ReportHeader'
import reportApi from '@/api/report'
import Enumerable from 'linq'
import LineChart from './LineChart'
import moment from 'moment'

export default {
  components: {
    LineChart,
    ReportHeader,
  },
  data () {
    return {
      loading: false,
      projects: [],
      presets: [],
      chartOptions: {
        maintainAspectRatio: false,
        scales: {
          xAxes: [{
            display: true,
            scaleLabel: {
              display: true,
              labelString: 'Month',
            },
          }],
          yAxes: [{
            display: true,
            scaleLabel: {
              display: true,
              labelString: 'State Duration Average (Days)',
            },
            ticks: {
              beginAtZero: true,
            },
          }],
        },
        tooltips: {
          callbacks: {
            label: this.tooltipLabel,
          },
        },
      },
      states: {
        'New': '#63B3ED',
        'Assigned Team': '#B794F4',
        'Quoted': '#F56565',
        'Open': '#F6AD55',
        'Pending Closure': '#7F9CF5',
      },
    }
  },
  computed: {
    periodMonths () {
      const { startDate, endDate } = this.$route.query
      let start = moment(startDate)
      const end = moment(endDate)
      const months = {}

      while (start.isBefore(end)) {
        months[start.format('MM/YYYY')] = null
        start = start.add(1, 'day')
      }

      return Object.keys(months)
    },
    chartData () {
      return {
        labels: this.periodMonths,
        datasets: Enumerable
          .from(this.projects)
          .where(project => Object.keys(this.states).includes(project.state_name))
          .groupBy(project => project.state_name)
          .select(stateGroup => {
            const averageDurationPerMonth = []
            for (const month of this.periodMonths) {
              let averageDuration = stateGroup
                .where(project => moment(project.end_date).format('MM/YYYY') === month)
                .average(project => project.state_duration)
              if (isNaN(averageDuration)) averageDuration = 0
              averageDurationPerMonth.push(averageDuration)
            }

            return {
              lineTension: 0,
              label: stateGroup.key(),
              backgroundColor: 'rgba(0,0,0,0)',
              fill: false,
              borderColor: this.states[stateGroup.key()],
              pointBackgroundColor: this.states[stateGroup.key()],
              data: averageDurationPerMonth,
            }
          })
          .toArray(),
      }
    },
  },
  beforeMount () {
    this.definePresets()
  },
  methods: {
    definePresets () {
      const endDate = moment().subtract(1, 'months').endOf('month').format('YYYY-MM-DD')
      this.presets = [
        {
          title: '3 Months',
          startDate: moment().subtract(3, 'months').startOf('month').format('YYYY-MM-DD'),
          endDate: endDate,
        },
        {
          title: '6 Months',
          startDate: moment().subtract(6, 'months').startOf('month').format('YYYY-MM-DD'),
          endDate: endDate,
        },
        {
          title: '9 Months',
          startDate: moment().subtract(9, 'months').startOf('month').format('YYYY-MM-DD'),
          endDate: endDate,
        },
        {
          title: '12 Months',
          startDate: moment().subtract(12, 'months').startOf('month').format('YYYY-MM-DD'),
          endDate: endDate,
        },
      ]
    },
    load () {
      const { groupId, teamId, serviceGroups, services, startDate, endDate } = this.$route.query
      if (!groupId) return

      this.projects = []
      this.loading = true

      reportApi.getStateDurationReport(groupId, teamId, startDate, endDate, serviceGroups, services).then(response => {
        this.projects = response.data
      }).finally(() => {
        this.loading = false
      })
    },
    tooltipLabel (tooltipItem, data) {
      let label = data.datasets[tooltipItem.datasetIndex].label || ''
      if (label) {
        label += ': '
      }
      label += Math.round(tooltipItem.yLabel * 10) / 10
      return label
    },
  },
}
</script>
