<template>
  <!-- Main content -->
  <div>
    <ReportHeader
      title="Week Report"
      group-filter
      year-filter
      week-filter
      team-filter
      class="mb-3"
      @filterChanged="load"
    />
    <!-- Reports start -->
    <div
      v-if="displayReport"
      id="exportPDF"
    >
      <div class="row">
        <div class="col-md-6 col-sm-6 col-xs-12">
          <BaseCard>
            <template #header>
              LAST WEEK
            </template>
            <table class="table prev-week-table">
              <tr>
                <th style="text-transform: capitalize;">
                  {{ firstState }}
                </th>
                <th>Quoted</th>
                <th>Open</th>
                <th>Pending Closure</th>
                <th>Closed</th>
              </tr>
              <tr>
                <td>{{ prev.total[firstState] }}</td>
                <td>{{ prev.total['Quoted'] }}</td>
                <td>{{ prev.total['Open'] }}</td>
                <td>{{ prev.total['Pending Closure'] }}</td>
                <td>{{ prev.total['Closed'] }}</td>
              </tr>
            </table>
          </BaseCard>
        </div>
        <div class="col-md-6 col-sm-6 col-xs-12">
          <BaseCard type="info">
            <template #header>
              THIS WEEK
            </template>
            <table class="table this-week-table">
              <tr>
                <th style="text-transform: capitalize;">
                  {{ firstState }}
                </th>
                <th>Quoted</th>
                <th>Open</th>
                <th>Pending Closure</th>
                <th>Closed</th>
              </tr>
              <tr>
                <td>{{ current.total[firstState] }}</td>
                <td>{{ current.total['Quoted'] }}</td>
                <td>{{ current.total['Open'] }}</td>
                <td>{{ current.total['Pending Closure'] }}</td>
                <td>{{ current.total['Closed'] }}</td>
              </tr>
            </table>
          </BaseCard>
        </div>
      </div>
      <div class="row">
        <div class="col-md-6 col-sm-6 col-xs-12">
          <div class="card text-center">
            <div class="card-header">
              As of {{ formatDate(prev.endDate) }}:
            </div>
            <div class="card-body">
              <p>- {{ prev.total[firstState] }} '{{ firstState }}' cases in our queue</p>
              <p>- {{ prev.total['Open'] }} 'Open' cases in our queue</p>
              <p>- {{ prev.total['Closed'] }} 'Closed' cases</p>
            </div>
            <div class="card-header">
              Week Summary:
            </div>
            <div class="card-body">
              <p>- {{ prev.new['Closed'] }} cases Closed</p>
              <p>- {{ prev.new[firstState] }} cases assigned ('{{ firstState }}')</p>
              <p>- {{ prev.new['Quoted'] }} Quoted</p>
              <p>- {{ prev.new['Open'] }} approved</p>
            </div>
          </div>
        </div>
        <div class="col-md-6 col-sm-6 col-xs-12">
          <div class="card border-info text-center">
            <div class="card-header bg-info text-white">
              As of {{ formatDate(current.endDate) }}:
            </div>
            <div class="card-body">
              <p>- {{ current.total[firstState] }} '{{ firstState }}' cases in our queue</p>
              <p>- {{ current.total['Open'] }} 'Open' cases in our queue</p>
              <p>- {{ current.total['Closed'] }} 'Closed' cases</p>
            </div>
            <div class="card-header bg-info text-white">
              Week Summary:
            </div>
            <div class="card-body">
              <p>- {{ current.new['Closed'] }} cases Closed</p>
              <p>- {{ current.new[firstState] }} cases assigned ('{{ firstState }}')</p>
              <p>- {{ current.new['Quoted'] }} Quoted</p>
              <p>- {{ current.new['Open'] }} approved</p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- Reports end -->
  </div>
</template>

<script>
import reportAPI from '@/api/report'
import ReportHeader from './ReportHeader'
import moment from 'moment'

export default {
  components: {
    ReportHeader,
  },
  data () {
    return {
      displayReport: false,
      teamId: null,
      current: {},
      prev: {},
      error: '',
    }
  },
  computed: {
    firstState () { return this.teamId ? 'Assigned Team' : 'New' },
  },
  methods: {
    /**
             * @description gets report information from database
             */
    load () {
      const { groupId, week, year, teamId } = this.$route.query
      this.teamId = teamId
      Promise.all([
        reportAPI.getWeekReport(groupId, week, year, teamId),
        reportAPI.getWeekReport(groupId, week - 1, year, teamId),
      ]).then(([current, prev]) => {
        this.current = current.data
        this.prev = prev.data
        this.displayReport = true
      })
    },
    formatDate (date) {
      return moment(date, 'YYYY-MM-DD').format('dddd DD-MM-YYYY')
    },
    /**
             * @description navigates to previous page
             */
    goBack () {
      this.$router.go(-1)
    },
  },
}
</script>

<style scoped>
    /* set table bg colour to grey */
    .prev-week-table {
        table-layout: fixed;
        background-color: #cecece;
    }

    /* set table bg colour to blue */
    .this-week-table {
        table-layout: fixed;
        background-color: #bfe8f2;
    }

    /* set table line colour to white, add padding*/
    .prev-week-table td, .prev-week-table th,
    .this-week-table td, .this-week-table th {
        border: 3px solid white;
        padding: 3px;
    }
</style>
