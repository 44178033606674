<template>
  <div>
    <ReportHeader
      title="CR Report"
      class="mb-3"
      period-filter
      @filterChanged="getData"
    >
      <GenericFilter
        title="Outcome"
        :options="outcomeOptions"
        route-param="outcome"
      />
      <GenericFilter
        title="Status"
        :options="statusOptions"
        route-param="status"
      />
      <GenericFilter
        title="CR Type"
        :options="crTypeOptions"
        route-param="crType"
      />
      <CustomFilter
        title="Group By"
        :options="['Week', 'Month']"
        default-value="Week"
        route-param="groupBy"
      />
    </ReportHeader>
    <div class="d-flex flex-wrap">
      <CRReportSummaryTable
        title="CR Type"
        :data="typeTable"
        :loading="loading"
      />
      <CRReportSummaryTable
        title="Status"
        :data="stateTable"
        :loading="loading"
      />
      <CRReportSummaryTable
        title="Outcome"
        :data="outcomeTable"
        :loading="loading"
      />
    </div>
    <!-- CR Type History -->
    <div class="row mb-3">
      <div class="col-lg-6">
        <h4 class="font-weight-bold">
          CR Type History
        </h4>
        <div class="bg-light rounded-lg p-4">
          <BarChart
            :chart-data="crTypeChartData"
            :options="chartOptions"
            :width="null"
            :height="null"
          />
        </div>
      </div>
      <!-- CR Status History -->
      <div class="col-lg-6">
        <h4 class="font-weight-bold">
          CR Status History
        </h4>
        <div class="bg-light rounded-lg p-4">
          <BarChart
            :chart-data="statusChartData"
            :options="chartOptions"
            :width="null"
            :height="null"
          />
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-lg-6">
        <!-- CR Outcome History -->
        <h4 class="font-weight-bold">
          CR Outcome History
        </h4>
        <div class="bg-light rounded-lg p-4">
          <BarChart
            :chart-data="outcomeChartData"
            :options="chartOptions"
            :width="null"
            :height="null"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ReportHeader from './ReportHeader'
import GenericFilter from './Filters/GenericFilter'
import CustomFilter from './Filters/CustomFilter'
import CRReportSummaryTable from './CRReportSummaryTable'
import BarChart from './BarChart'
import bookingApi from '@/api/booking'
import moment from 'moment'

export default {
  components: {
    ReportHeader,
    GenericFilter,
    CustomFilter,
    CRReportSummaryTable,
    BarChart,
  },
  beforeRouteEnter (to, from, next) {
    if (!to.query || !to.query.startDate) {
      // set default start date 15 weeks ago
      const startDate = moment().startOf('isoWeek').subtract(15, 'weeks').format('YYYY-MM-DD')
      next({ path: to.path, query: { ...to.query, startDate } })
    } else {
      next()
    }
  },
  beforeRouteUpdate (to, from, next) {
    if (!to.query || !to.query.startDate) {
      // set default start date 15 weeks ago
      const startDate = moment().startOf('isoWeek').subtract(15, 'weeks').format('YYYY-MM-DD')
      next({ path: to.path, query: { ...to.query, startDate } })
    } else {
      next()
    }
  },
  data () {
    return {
      crData: new Map(),
      typeTable: [],
      stateTable: [],
      outcomeTable: [],
      error: '',
      loading: false,
      defaultColours: [
        '#f39c12',
        '#e6805f',
        '#00a65a',
        '#b04012',
        '#11cc12',
        '#aa5511',
        '#f30012',
        '#ffce56',
        '#1fc8db',
        '#fce473',
        '#42afe3',
        '#ed6c63',
      ],
      crTypes: [
        ['Normal CR', '#f39c12'],
        ['Standard CR', '#e6805f'],
        ['Model CR', '#00a65a'],
        ['Expedited CR', '#b04012'],
      ],
      statuses: [
        ['Scheduled for Implementation', '#f39c12'],
        ['Successfully Completed', '#e6805f'],
        ['Failed', '#00a65a'],
        ['Rollback', '#b04012'],
        ['Partially Implemented', '#11cc12'],
        ['Re-scheduled', '#aa5511'],
        ['Rejected', '#f30012'],
        ['Cancelled', '#ffce56'],
      ],
      outcomes: [
        ['Scenario not as expected', '#f39c12'],
        ['Incorrect project inputs', '#e6805f'],
        ['Missing configuration (L2)', '#00a65a'],
        ['Network incident', '#b04012'],
        ['Embargo', '#11cc12'],
        ['Duplicated/Back-up', '#aa5511'],
        ['Not approved in time', '#f30012'],
        ['Product limitation or bug', '#ffce56'],
        ['Under investigation', '#1fc8db'],
        ['Conflicting change', '#fce473'],
        ['No longer required', '#42afe3'],
        ['Customer not ready', '#ed6c63'],
      ],
      chartOptions: {
        tooltips: {
          displayColors: true,
          callbacks: {
            mode: 'x',
          },
        },
        scales: {
          xAxes: [{
            stacked: true,
            gridLines: {
              display: false,
            },
          }],
          yAxes: [{
            stacked: true,
            ticks: {
              beginAtZero: true,
              precision: 0,
            },
            type: 'linear',
          }],
        },
      },
    }
  },
  computed: {
    crTypeOptions () {
      return this.crTypes.map(t => t[0])
    },
    statusOptions () {
      return this.statuses.map(t => t[0])
    },
    outcomeOptions () {
      return this.outcomes.map(t => t[0])
    },
    chartLabels () {
      return Array.from(this.crData.keys())
    },
    crTypeChartData () {
      return {
        labels: this.chartLabels,
        datasets: this.crTypes.map(([crType, colour]) => {
          return {
            label: crType,
            backgroundColor: colour,
            data: this.chartLabels.map(label => this.crData.get(label).filter(cr => cr.cr_type === crType).length),
          }
        }),
      }
    },
    statusChartData () {
      return {
        labels: this.chartLabels,
        datasets: this.statuses.map(([status, colour]) => {
          return {
            label: status,
            backgroundColor: colour,
            data: this.chartLabels.map(label => this.crData.get(label).filter(cr => cr.status === status).length),
          }
        }),
      }
    },
    outcomeChartData () {
      return {
        labels: this.chartLabels,
        datasets: this.outcomes.map(([outcome, colour]) => {
          return {
            label: outcome,
            backgroundColor: colour,
            data: this.chartLabels.map(label => this.crData.get(label).filter(cr => cr.outcome === outcome).length),
          }
        }),
      }
    },
  },
  methods: {
    reset () {
      this.typeTable = []
      this.stateTable = []
      this.outcomeTable = []
    },
    getData () {
      this.reset()
      const { startDate, endDate, crType, status, outcome, groupBy } = this.$route.query
      const params = {}
      if (startDate) {
        params.start_date_gte = startDate
      }
      if (endDate) {
        params.start_date_lt = endDate
      }
      if (crType) {
        params.cr_type = crType
      }
      if (status) {
        params.status = status
      }
      if (outcome) {
        params.outcome = outcome
      }
      this.loading = true
      bookingApi.searchCRs(params).then(response => {
        let labelFormat = 'GGGG-[W]WW'
        let periodSummary = 'week'
        let startOf = 'isoWeek'
        if (groupBy === 'Month') {
          labelFormat = 'YYYY-MMM'
          periodSummary = 'month'
          startOf = 'month'
        }
        const typeSummary = {}
        const stateSummary = {}
        const outcomeSummary = {}
        // build map to store data for graphs
        const crData = new Map()
        const startPeriod = moment(startDate, 'YYYY-MM-DD').startOf(startOf)
        const endPeriod = moment(endDate, 'YYYY-MM-DD').startOf(startOf)
        for (let m = startPeriod; m.isSameOrBefore(endPeriod); m.add(1, periodSummary)) {
          crData.set(m.format(labelFormat), [])
        }
        response.data.data.forEach(cr => {
          // update summaries
          typeSummary[cr.cr_type] = (typeSummary[cr.cr_type] || 0) + 1
          stateSummary[cr.status] = (stateSummary[cr.status] || 0) + 1
          if (cr.outcome) {
            outcomeSummary[cr.outcome] = (outcomeSummary[cr.outcome] || 0) + 1
          }
          // store crs by period
          const week = moment(cr.start_date, 'YYYY-MM-DD').startOf(startOf).format(labelFormat)
          const weekData = crData.get(week)
          weekData.push(cr)
          crData.set(week, weekData)
        })
        this.typeTable = Object.entries(typeSummary)
        this.stateTable = Object.entries(stateSummary)
        this.outcomeTable = Object.entries(outcomeSummary)
        this.crData = crData

        // calculate successful CR % rate
        let success = stateSummary['Successfully Completed'] || 0
        const tot = success + (stateSummary.Rollback || 0) + (stateSummary['Partially Implemented'] || 0) + (stateSummary.Failed || 0)
        if (tot > 0) {
          success = Math.round((success * 100) / tot)
        }
        success = success.toString() + '%'
        this.stateTable = this.stateTable.concat([['', ''], ['CR Success rate', success]])

        this.loading = false
      }).catch(error => {
        this.error = error.message
        this.loading = false
      })
    },
  },
}
</script>
