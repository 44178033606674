<template>
  <div>
    <!-- Header -->
    <div class="d-flex flex-wrap align-items-center">
      <!-- Title -->
      <h1 class="flex-grow-1 h3">
        {{ title }}
      </h1>
      <!-- Groups Filter -->
      <div
        v-if="groupFilter"
        class=""
      >
        <GroupFilter v-bind="groupFilterOptions" />
      </div>
    </div>
    <!-- Sub Header -->
    <div class="d-flex flex-wrap align-items-center flex-row-reverse sub-header">
      <slot />
      <CategoryFilter v-if="categoryFilter" />
      <TeamFilter v-if="teamFilter" />
      <WeekFilter v-if="weekFilter" />
      <YearFilter v-if="yearFilter" />
      <StateFilter v-if="stateFilter" />
      <PeriodFilter
        v-if="periodFilter"
        v-bind="periodFilterOptions"
      />
    </div>
  </div>
</template>

<script>
import GroupFilter from './Filters/GroupFilter'
import PeriodFilter from './Filters/PeriodFilter'
import CategoryFilter from './Filters/CategoryFilter'
import TeamFilter from './Filters/TeamFilter'
import YearFilter from './Filters/YearFilter'
import WeekFilter from './Filters/WeekFilter'
import StateFilter from './Filters/StateFilter'

export default {
  components: {
    WeekFilter,
    YearFilter,
    TeamFilter,
    CategoryFilter,
    PeriodFilter,
    GroupFilter,
    StateFilter,
  },
  props: {
    title: {
      type: String,
      required: true,
    },
    periodFilter: {
      type: Boolean,
      default: false,
    },
    periodFilterOptions: {
      type: Object,
      default () {
        return { }
      },
    },
    categoryFilter: {
      type: Boolean,
      default: false,
    },
    groupFilter: {
      type: Boolean,
      default: false,
    },
    groupFilterOptions: {
      type: Object,
      default () {
        return { }
      },
    },
    teamFilter: {
      type: Boolean,
      default: false,
    },
    yearFilter: {
      type: Boolean,
      default: false,
    },
    weekFilter: {
      type: Boolean,
      default: false,
    },
    stateFilter: {
      type: Boolean,
      default: false,
    },
  },
  watch: {
    '$route.query' () {
      this.onFilterChanged()
    },
  },
  beforeMount () {
    this.onFilterChanged()
  },
  methods: {
    onFilterChanged () {
      const { groupId, startDate, endDate, services, serviceGroups, teamId, year, week, states } = this.$route.query

      if (this.stateFilter && !states) return
      if (this.weekFilter && !week) return
      if (this.yearFilter && !year) return
      if (this.groupFilter && !groupId) return
      if (this.periodFilter && (!startDate || !endDate)) return

      this.$emit('filterChanged', {
        groupId,
        startDate,
        endDate,
        services,
        serviceGroups,
        teamId,
        year,
        week,
      })
    },
  },
}
</script>

<style lang="scss" scoped>
.sub-header ::v-deep > * {
  margin-top: 16px;
  margin-left: 24px;
}
</style>
