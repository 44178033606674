<template>
  <!-- Main content -->
  <div>
    <ReportHeader
      title="New Projects By Platform"
      group-filter
      team-filter
      @filterChanged="load"
    />
    <!-- Loading Indicator-->
    <div
      v-if="loading"
      class="d-flex align-items-center justify-content-center p-5"
    >
      <div class="spinner-border" />
    </div>
    <!-- Report Chart -->
    <div v-else>
      <div
        v-show="displayReport"
        class="row justify-content-around"
      >
        <div class="col-5">
          <h3 class="text-center">
            Current Month
          </h3>
          <PieChart
            :chart-data="monthData"
            :options="options"
            :width="null"
            :height="null"
          />
        </div>
        <div class="col-5">
          <h3 class="text-center">
            Year
          </h3>
          <PieChart
            :chart-data="yearData"
            :options="options"
            :width="null"
            :height="null"
          />
        </div>
      </div>
    </div>
    <!-- Download Buttons -->
    <ReportDownloadButtons
      csv-filename="platform_report.csv"
      :csv-data="csvData"
    />
    <!-- Errors -->
    <div
      v-if="error"
      class="alert alert-danger"
    >
      {{ error }}
    </div>
    <!-- Graph end -->
  </div>
</template>

<script>
/**
  * View to show Platforms Report
  */
import PieChart from './PieChart.js'
import reportAPI from '@/api/report'
import ReportHeader from './ReportHeader'
import ReportDownloadButtons from './ReportDownloadButtons'

export default {
  components: {
    PieChart,
    ReportHeader,
    ReportDownloadButtons,
  },
  data () {
    return {
      domainMonth: {
        backgroundColor: [],
        data: [],
        labels: [],
      },
      domainYear: {
        backgroundColor: [],
        data: [],
        labels: [],
      },
      monthData: {
        labels: [],
        datasets: [],
      },
      yearData: {
        labels: [],
        datasets: [],
      },
      backgroundColor: [
        '#66c2a5',
        '#fc8d62',
        '#8da0cb',
        '#e78ac3',
        '#a6d854',
        '#ffd92f',
        '#e5c494',
      ],
      colourMap: {
        'DC': 'rgb(242,146,38)',
        'IP Core': 'rgb(121,189,157)',
        '3PA': 'rgb(242,212,116)',
        'Retail': 'rgb(39,104,169)',
        'Corporate': 'rgb(94,181,225)',
        'CustCare': 'rgb(210,80,80)',
        'Paging': 'rgb(150,121,189)',
        'Network Services': 'rgb(206,206,206)',
      },
      csvData: [],
      displayReport: false,
      error: '',
      loading: false,
      options: {
        aspectRatio: 1,
        legend: {
          position: 'bottom',
        },
      },
    }
  },
  methods: {
    load () {
      const { groupId, teamId } = this.$route.query
      if (!groupId) return

      this.loading = true

      reportAPI.getPlatformReport(groupId, teamId).then(resp => {
        this.loading = false
        this.setData(resp.data)
      }).catch(error => {
        this.loading = false
        this.error = error.message
      })
    },
    buildChartData (data, colours, colourMap) {
      const labels = []
      const dataset = { data: [], backgroundColor: [] }

      data.filter(s => s.count !== 0).forEach(s => {
        labels.push(s.domain)
        dataset.data.push(s.count)
        let colour = colourMap[s.domain]
        if (!colour) {
          // get new default colour
          colour = colours.pop()
          // save new colour mapping so colour matches in year graph
          colourMap[s.domain] = colour
        }
        dataset.backgroundColor.push(colour)
      })
      return { labels: labels, datasets: [dataset] }
    },
    buildCSV () {
      // build csv
      const csv = []
      // add month headers
      csv.push(this.monthData.labels.slice())
      csv[0].unshift('Period\\Categories')
      // add month data
      const monthData = this.monthData.datasets[0].data.slice()
      monthData.unshift('Month')
      csv.push(monthData)
      // add year headers
      csv.push(this.yearData.labels.slice())
      csv[2].unshift('Period\\Categories')
      // add year data
      const yearData = this.yearData.datasets[0].data.slice()
      yearData.unshift('Year')
      csv.push(yearData)

      this.csvData = csv
    },
    /**
      * @description sets up csv with data and sets up graph data
      * @param []{string, int} domain_stats, []{string, int} domain_month_stats
      */
    setData (data) {
      // copy colours so we can allocate colours to domains
      const colours = this.backgroundColor.slice()
      // copy mapping so we can add domains with no allocated colours
      const colourMap = Object.assign({}, this.colourMap)
      // build month data
      this.monthData = this.buildChartData(data.domain_month_stats, colours, colourMap)
      // build year data
      this.yearData = this.buildChartData(data.domain_stats, colours, colourMap)

      this.buildCSV()
      this.displayReport = true
    },
    /**
      * @description navigates to previous page
      */
    goBack () {
      this.$router.go(-1)
    },
  },
}
</script>
