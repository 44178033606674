<template>
  <div>
    <div
      class="d-flex align-items-center"
      style="cursor: pointer;"
      @click="edit"
    >
      <div class="font-weight-bold mr-2 text-nowrap">
        {{ title }}:
      </div>
      <div
        class="form-control"
        :class="selectedOptions.length > 0 ? 'text-primary' : ''"
      >
        {{ selectedOptions.length === 0 ? 'Any' : (selectedOptions.length + ' Selected') }}&nbsp;&#9207;
      </div>
    </div>
    <!-- Selector Modal -->
    <Modal
      ref="modal"
      :title="`${title} Filter`"
      scrollable
    >
      <!-- Done Action -->
      <template #header-action>
        <button
          class="btn btn-lg btn-primary"
          @click.prevent="submitOptions"
        >
          Done
        </button>
      </template>

      <!-- Select Actions -->
      <div class="d-flex flex-row-reverse mb-3">
        <button
          class="btn btn-light mx-1"
          @click="selectAll"
        >
          Select All
        </button>
        <button
          class="btn btn-light mx-1"
          @click="clear"
        >
          Clear All
        </button>
      </div>
      <!--  Options -->
      <div
        v-for="opt in options"
        :key="optionKey(opt)"
        class="d-flex align-items-center rounded-lg px-3 py-2 mb-1"
        style="cursor: pointer;"
        :class="editOptions.includes(optionKey(opt)) ? 'text-info font-weight-bold bg-gray-300' : 'text-gray-600 hoverable'"
        @click="toggleOption(opt)"
      >
        <div class="flex-grow-1">
          {{ optionLabel(opt) }}
        </div>
        <div v-show="editOptions.includes(optionKey(opt))">
          <font-awesome-icon
            icon="check-square"
            fixed-width
          />
        </div>
      </div>
    </Modal>
  </div>
</template>

<script>
import Modal from '@/components/Modal'

export default {
  components: {
    Modal,
  },
  props: {
    title: {
      type: String,
      required: true,
    },
    options: {
      type: Array,
      required: true,
    },
    routeParam: {
      type: String,
      required: true,
    },
    trackBy: {
      type: String,
      default: '',
    },
    label: {
      type: String,
      default: '',
    },
  },
  data () {
    return {
      editOptions: [],
    }
  },
  computed: {
    selectedOptions: {
      get () {
        const selected = this.$route.query[this.routeParam] || []
        return Array.isArray(selected) ? selected : [selected]
      },
      set (value) {
        const query = { ...this.$route.query }
        query[this.routeParam] = value
        this.$router.replace({ path: this.$route.path, query: query })
      },
    },
  },
  methods: {
    optionKey (opt) {
      return this.trackBy ? opt[this.trackBy] : opt
    },
    optionLabel (opt) {
      return this.label ? opt[this.label] : opt
    },
    isSelected (opt) {
      this.editOptions.includes(this.optionKey(opt))
    },
    toggleOption (opt) {
      const k = this.optionKey(opt)
      const i = this.editOptions.findIndex(o => o === k)
      if (i >= 0) {
        this.editOptions.splice(i, 1)
      } else {
        this.editOptions.push(k)
      }
    },
    selectAll () {
      if (this.trackBy) {
        this.editOptions = this.options.map(o => o[this.trackBy])
      } else {
        this.editOptions = this.options.slice()
      }
    },
    clear () {
      this.editOptions = []
    },
    edit () {
      this.editOptions = this.selectedOptions.slice()
      this.$refs.modal.show()
    },
    submitOptions () {
      this.$refs.modal.hide()
      this.selectedOptions = this.editOptions
    },
  },
}
</script>
